// import React, { Component } from "react";
// import DashboardLayout from "../../layouts/DashboardLayout";
// import { Row, Col, Tabs, Tab, Spinner } from "react-bootstrap";
// import "./index.css";
// import CusDataTable from "../../components/CusDataTable";
// import Tooltip from "@mui/material/Tooltip";
// import SemiCircleProgressBar from "react-progressbar-semicircle";
// import LinkIcon from "../../assets/images/icons/link-icon.svg";
// import AddIcon from "../../assets/images/icons/add-icon.svg";
// import ResetIcon from "../../assets/images/icons/reset-icon.svg";
// import ShareIcon from "../../assets/images/icons/share-icon.svg";
// import CalenderIcon from "../../assets/images/icons/calendar-icon.svg";
// import FilterIcon from "../../assets/images/icons/filter-icon.svg";
// import MoreActionIcon from "../../assets/images/icons/more-action-icon.svg";
// import InfoCircleIcon from "../../assets/images/icons/info-circle-icon.svg";
// import Image1 from "../../assets/images/Image-1.png";
// import Image2 from "../../assets/images/Image-2.png";
// import Image3 from "../../assets/images/Image-3.png";
// import Image4 from "../../assets/images/Image-4.png";
// import { Link } from "react-router-dom";
// import Moment from "moment";
// import moment from "moment";
// import Areachart from "../../components/Charts/Areachart";
// import Charts from "../../components/Charts/Chart";
// import ChartsRed from "../../components/Charts/Chart-red";
// import Stackedbar from "../../components/Charts/Stackedbar";
// import PauseIcon from "../../assets/images/icons/pause.svg";
// import ExportIcon from "../../assets/images/icons/export.svg";
// import BooksTable from "./table/books-table";
// import AuthorsTable from "./table/author-table";
// import MarketplaceTable from "./table/marketplace-table";
// import BestSeller from "./bestSeller/bestSeller";
// import { DateRangePicker } from "rsuite";
// import isAfter from "date-fns/isAfter";
// import RoyaltiesProjected from "../../components/Charts/RoyaltiesProjectedChart";
// import SpendChart from "../../components/Charts/SpendChart";
// import RoyaltiesProjectedChart from "../../components/Charts/RoyaltiesProjectedChart";
// import RoyaltiesDistributionChart from "../../components/Charts/RoyaltiesDistributionChart";
// import { Modal } from "antd";
// import Synckdpextension from "./Synckdpextension";
// import SyncDataDaily from "./SyncDataDaily";
// let advancedFilterDateRange: any = [];
// interface Metric {
//   metricName: string;
//   value: number;
//   avgvalue: number;
// }
// type Marketplace = {
//   name: string;
//   value: string;
// };

// type BookFormat = {
//   name: string;
//   value: string;
// };

// export default class Dashboard extends Component {
//   filterData: any = [];
//   current = new Date();
//   startDate = new Date();
//   endDate = new Date();
//   startDateFilter =
//     Moment(this.startDate).format("YYYY-MM-DD") + "T00:00:01.064Z";

//   endDateFilter = Moment(this.endDate).format("YYYY-MM-DD") + "T23:50:59.064Z";
//   constructor(props) {
//     super(props);
//     this.onChangeDateRangeSubmitGlobal =
//       this.onChangeDateRangeSubmitGlobal.bind(this);

//     const endDate = new Date();
//     const startDate = new Date();
//     startDate.setDate(endDate.getDate() - 30);
//     this.startDate.setDate(this.endDate.getDate() - 30);
//   }

//   state = {
//     ispopup: false,
//     issearchText: {
//       titlesearch: "",
//       campainsearch: "",
//       authorsearch: "",
//       booksearch: "",
//       marketsearch: "",
//     },
//     ispopupauthor: false,
//     ispoupcampain: false,
//     ispopupmarket: false,
//     startDate: this.startDate,
//     endDate: this.endDate,
//     syncData: "",
//     globalFilterFromData: [],
//     filteredmarketData: [],
//     filteredauthorData: [],
//     bestSellerData: [],
//     campainData: [],
//     bookData: [],
//     filteredbookData: [],
//     bookDataList: [],
//     campainDataList: [],
//     filteredcampainData: [],
//     titleData: [],
//     titleDataList: [],
//     marketDataList: [],
//     authorDataList: [],
//     filteredTitleData: [],
//     marketData: [],
//     authorData: [],
//     salesAvgValue: "",
//     salesMetricName: "",
//     salesValue: "",
//     budgetAvgValue: "",
//     budgetMetricName: "",
//     budgetValue: "",
//     selectedValue: "",
//     CPCAvgValue: "",
//     CPCMetricName: "",
//     CPCValue: "",
//     orderAvgValue: "",
//     orderMetricName: "",
//     orderValue: "",
//     searchKeyFilter: "",
//     CRData1: "",
//     CRData2: "",
//     CRAvarageValue: "",
//     CRPercentage: "",
//     loading: false,
//     categories: "",
//     salesAvarageValue: "",
//     salesPercentage: "",
//     royaltiesDistributionValue: "",
//     royaltiesDistributionPercentage: "",
//     royaltiesProjectedValue: "",
//     royaltiesProjectedPercentage: "",
//     globalFilterDateRange: [this.startDate, this.endDate],
//     spendChartData: [],
//     RoyaltiesDistributionChart: [],
//     SpenLoading: false,
//     isModalVisible: false,
//     isSyncDataVisible: true,
//     lastUpdatedDate: localStorage.getItem("lastUpdatedDate") || null,
//     metrics: [] as Metric[],
//     selectedMarketplace: "",
//     selectedMetricCPC: {
//       metricName: "",
//       value: 0,
//       avgvalue: 0,
//     },
//     selectedMetricBudget: {
//       metricName: "",
//       value: 0,
//       avgvalue: 0,
//     },
//     selectedMetricSales: {
//       metricName: "",
//       value: 0,
//       avgvalue: 0,
//     },
//     selectedMetricOrder: {
//       metricName: "",
//       value: 0,
//       avgvalue: 0,
//     },
//     metricOptions: [] as string[],
//     metricNames: [] as string[],
//     seriesDataNames: [],
//     selectedMetric: "",
//     metaData: {
//       data: {
//         marketplaces: [] as Marketplace[],
//         bookFormats: [] as BookFormat[],
//       },
//     },
//     selectedBookFormat: "",
//     ispopupbookformat: false,
//     bookFormats: [] as BookFormat[],
//     marketplaces: [] as Marketplace[],
//     ispopupmarketplace: false,
//     selectedChart: "",

//     selectedMetricSpend: "",
//   };

//   componentDidMount(): void {
//     this.getMetaData();
//     this.titleHandler();
//     const dateRangeLabel = "Custom"; // Replace this with your actual value
//     // this.titleHandler();
//     this.getSyncStatus();
//     this.getSalesDateRange(
//       dateRangeLabel,
//       this.state.startDate,
//       this.state.endDate
//     );
//     this.getCPCDateRange(
//       dateRangeLabel,
//       this.state.startDate,
//       this.state.endDate
//     );
//     this.getBudgetDateRange(
//       dateRangeLabel,
//       this.state.startDate,
//       this.state.endDate
//     );
//     this.getCR();
//     this.getSpendChartData(
//       dateRangeLabel,
//       this.state.startDate,
//       this.state.endDate
//     );
//     this.getOrderDateRange(
//       dateRangeLabel,
//       this.state.startDate,
//       this.state.endDate
//     );
//     this.getChartRoyaltiesDistributionData(
//       dateRangeLabel,
//       this.state.startDate,
//       this.state.endDate
//     );
//     this.getChartRoyaltiesProjectedData(
//       dateRangeLabel,
//       this.state.startDate,
//       this.state.endDate
//     );

//     console.log("Date=" + this.state.globalFilterDateRange);
//     const lastDisplayedTime = localStorage.getItem("lastDisplayedTime");
//     const currentTime = new Date().getTime();
//     const lastSyncDate = localStorage.getItem("lastSyncDate");

//     if (lastSyncDate) {
//       this.setState({ syncData: lastSyncDate });
//     }
//     if (
//       lastDisplayedTime &&
//       currentTime - parseInt(lastDisplayedTime, 10) < 24 * 60 * 60 * 1000
//     ) {
//       this.setState({ isSyncDataVisible: false });
//     }
//     this.setState({
//       RoyaltiesDistributionChart: [],
//     });
//   }

//   getSyncStatus = async () => {
//     try {
//       let userToken = localStorage.getItem("userToken");
//       let AuthToken = "Bearer " + userToken;

//       const response = await fetch("https://api.aimosa.io/Dashboard/LastSync", {
//         method: "GET",
//         headers: {
//           accept: "application/json",
//           Authorization: AuthToken,
//         },
//       });

//       const syncStatusData = await response.json();

//       if (syncStatusData.success) {
//         const lastResult = syncStatusData.result;

//         if (lastResult === "Not Synced yet") {
//           console.log("Data not synced yet");
//           localStorage.setItem("lastUpdatedDate", "Not Synced yet");
//           this.setState({ lastUpdatedDate: "Not Synced yet" });
//         } else {
//           const formattedDate = new Date(lastResult.lastKDPSync);

//           // Get day and month names
//           const dayName = formattedDate.toLocaleDateString("en-US", {
//             weekday: "long",
//           });
//           const monthName = formattedDate.toLocaleDateString("en-US", {
//             month: "long",
//           });

//           // Continue with the existing logic to set the formatted date in the state
//           const month = (formattedDate.getMonth() + 1)
//             .toString()
//             .padStart(2, "0");
//           const day = formattedDate.getDate().toString().padStart(2, "0");
//           const year = formattedDate.getFullYear();
//           const hours = formattedDate.getHours().toString().padStart(2, "0");
//           const minutes = formattedDate
//             .getMinutes()
//             .toString()
//             .padStart(2, "0");
//           const seconds = formattedDate
//             .getSeconds()
//             .toString()
//             .padStart(2, "0");

//           const ampm = parseInt(hours, 10) >= 12 ? "PM" : "AM";

//           const displayHours = parseInt(hours, 10) % 12 || 12;

//           // Combine the formatted date, day, month, and AM/PM
//           const displayDate = `${dayName}, ${monthName} ${day}, ${year} ${displayHours}:${minutes}:${seconds} ${ampm}`;

//           localStorage.setItem("lastUpdatedDate", displayDate);

//           this.setState({ lastUpdatedDate: displayDate });
//         }
//       } else {
//         console.error("API request failed:", syncStatusData.message);
//       }
//     } catch (error) {
//       console.error("Error fetching sync status:", error);
//     }
//   };

//   getSpendChartData = async (dateRangeLabel, startDate, endDate) => {
//     try {
//       this.setState({
//         salesAvarageValue: (
//           <Spinner animation="border" role="status">
//             <span className="visually-hidden">Loading...</span>
//           </Spinner>
//         ),
//       });
//       this.setState({ SpenLoading: true });

//       let userToken = localStorage.getItem("userToken");
//       let AuthToken = "Bearer " + userToken;

//       const responseSpend = await fetch(
//         "https://api.aimosa.io/Dashboard/ChartData",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: AuthToken,
//           },
//           body: JSON.stringify({
//             globalFilters: {
//               searchText: "",
//               authors: [],
//               bookFormats: [],
//               ...(dateRangeLabel === "OK"
//                 ? {
//                     dateRanges: {
//                       dateRange: "Custom",
//                       startDate: startDate,
//                       endDate: endDate,
//                     },
//                   }
//                 : {
//                     dateRanges: {
//                       dateRange: dateRangeLabel,
//                     },
//                   }),

//               titles: this.state.titleDataList,
//               campaigns: [],
//               platforms: [],
//               marketPlaces: [],
//               adTypes: [],
//               advancedFilters: [],
//             },
//             name: "LineChart",
//           }),
//         }
//       );
//       console.log("Selected Start Date:", this.state.startDate);
//       console.log("Selected End Date:", this.state.endDate);

//       const responseChartData = await responseSpend.json();
//       responseChartData.result.seriesData.forEach((series, index) => {
//         console.log(`Series ${index + 1}:`, series);
//       });
//       var chartData = responseChartData.result;
//       this.setState({ spendChartData: chartData });
//       this.setState({
//         salesAvarageValue: chartData.seriesData[0].avrage.toFixed(3),
//       });
//       this.setState({
//         salesPercentage: chartData.seriesData[0].percentageChange.toFixed(1),
//       });

//       this.setState({ SpenLoading: false });
//     } catch (error) {
//       console.error(error);
//       return error;
//     }
//   };
//   getChartRoyaltiesDistributionData = async (
//     dateRangeLabel,
//     startDate,
//     endDate
//   ) => {
//     try {
//       this.setState({
//         royaltiesDistributionValue: (
//           <Spinner animation="border" role="status">
//             <span className="visually-hidden">Loading...</span>
//           </Spinner>
//         ),
//       });
//       const { startDate, endDate } = this.state;

//       // Assuming you want to use the same start and end date for royalties
//       await this.getSpendChartData("OK", startDate, endDate);

//       let userToken = localStorage.getItem("userToken");
//       let AuthToken = "Bearer " + userToken;

//       const responseBudget = await fetch(
//         "https://api.aimosa.io/Dashboard/ChartData",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: AuthToken,
//           },
//           body: JSON.stringify({
//             ...(dateRangeLabel === "OK"
//               ? {
//                   dateRanges: {
//                     dateRange: "Custom",
//                     startDate: startDate,
//                     endDate: endDate,
//                   },
//                 }
//               : {
//                   dateRanges: {
//                     dateRange: dateRangeLabel,
//                   },
//                 }),
//             name: "LineChart",
//           }),
//         }
//       );

//       const responseChartData = await responseBudget.json();

//       console.log("Distribution Response Chart Data:", responseChartData);

//       if (responseChartData.success) {
//         const chartData = responseChartData.result;
//         this.setState({ RoyaltiesDistributionChart: chartData });
//         if (chartData.seriesData) {
//           const selectedMetric = this.state.selectedChart;
//           const selectedMetricData = chartData.seriesData.find(
//             (metric) => metric.name === selectedMetric
//           );

//           if (selectedMetricData) {
//             this.setState({
//               royaltiesDistributionValue: selectedMetricData.average || 0,
//               royaltiesDistributionPercentage:
//                 selectedMetricData.percentageChange || 0,
//             });
//             console.log(selectedMetricData);
//           } else {
//             console.error("Selected metric data not found.");
//           }
//         } else {
//           console.error("No seriesData found in the response.");
//         }
//       } else {
//         console.error("API request failed:", responseChartData.message);
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//       return error;
//     }
//   };

//   getChartRoyaltiesProjectedData = async (
//     dateRangeLabel,
//     startDate,
//     endDate
//   ) => {
//     try {
//       this.setState({
//         royaltiesProjectedValue: (
//           <Spinner animation="border" role="status">
//             <span className="visually-hidden">Loading...</span>
//           </Spinner>
//         ),
//       });
//       let userToken = localStorage.getItem("userToken");
//       let AuthToken = "Bearer " + userToken;
//       const responseBudget = await fetch(
//         "https://api.aimosa.io/Dashboard/ChartData",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: AuthToken,
//           },
//           body: JSON.stringify({
//             dateRanges: {
//               dateRange: "Custom",
//               startDate: this.startDateFilter,
//               endDate: this.endDateFilter,
//             },
//             name: "LineChart",
//           }),
//         }
//       );
//       try {
//         const responseChartData = await responseBudget.json();
//         console.log("RoyaltiesProjected==", responseChartData.result);
//         var chartData = responseChartData.result;

//         if (chartData.seriesData[0].avrage) {
//           this.setState({
//             royaltiesProjectedValue: chartData.seriesData[0].avrage,
//           });
//         }
//         if (chartData.seriesData[0].percentageChange) {
//           this.setState({
//             royaltiesDistributionPercentage:
//               chartData.seriesData[0].percentageChange,
//           });
//         }
//       } catch {
//         this.setState({
//           royaltiesProjectedPercentage: 0,
//         });
//         this.setState({
//           royaltiesProjectedValue: 0,
//         });
//       }
//     } catch (error) {
//       console.error(error);
//       return error;
//     }
//   };
//   lastAppSyncClick = () => {
//     this.setState({
//       isModalVisible: true,
//     });
//   };

//   handleModalClose = () => {
//     this.setState({
//       isModalVisible: false,
//     });
//   };
//   getCR = async () => {
//     try {
//       this.setState({ loading: true });
//       let userToken = localStorage.getItem("userToken");
//       let AuthToken = "Bearer " + userToken;

//       const response = await fetch(
//         "https://api.aimosa.io/Dashboard/ChartData",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: AuthToken,
//           },
//           body: JSON.stringify({
//             name: "DonutPie",
//             dateRanges: {
//               dateRange: this.state.selectedValue,
//               startDate: this.startDateFilter,
//               endDate: this.endDateFilter,
//             },
//           }),
//         }
//       );
//       const responceData = await response.json();
//       console.log(this.state.selectedValue);
//       console.log("dataCR=" + responceData.result.seriesData[0].data);

//       // Check if seriesData is not null, undefined, or an empty array before mapping
//       if (
//         responceData.result.seriesData &&
//         responceData.result.seriesData.length > 0
//       ) {
//         console.log(
//           "seriesData names:",
//           responceData.result.seriesData.map((item) => item.name)
//         );

//         // Update the state with the names
//         this.setState({
//           seriesDataNames: responceData.result.seriesData.map(
//             (item) => item.name
//           ),
//         });
//       }

//       let cr = responceData.result.seriesData[0].data + "";
//       let crrr: any = cr.split(",", 1);
//       let dataFirstValue = parseInt(crrr, 10);
//       var data1 = dataFirstValue.toFixed();
//       let c = responceData.result.seriesData[0].data + "";
//       let crrrr: any = c.split(",");
//       let dataSecondValue = parseInt(crrrr[1], 10);
//       var data2 = dataSecondValue.toFixed();
//       var crData = responceData.result.seriesData[0];

//       this.setState({ CRData1: data1 });
//       this.setState({ CRData2: data2 });
//       this.setState({
//         CRAvarageValue: responceData.result.seriesData[0].avrage.toFixed(),
//       });
//       this.setState({
//         CRPercentage:
//           responceData.result.seriesData[0].percentageChange.toFixed(2),
//       });
//     } catch (error) {
//       console.error(error);
//       return error;
//     }
//   };

//   onChangeDateRangeSubmitGlobal = async (selectedDates, event) => {
//     try {
//       const dateRangeLabel = event?.target?.innerText || "Custom";

//       if (selectedDates.length > 0) {
//         const startDate =
//           Moment(selectedDates[0]).format("YYYY-MM-DD") + "T00:00:00.000Z";
//         const endDate =
//           Moment(selectedDates[1]).format("YYYY-MM-DD") + "T23:59:59.999Z";

//         this.setState({ globalFilterDateRange: selectedDates });

//         let advancedFilterDateRange = {
//           dateRange: dateRangeLabel,
//           startDate: startDate,
//           endDate: endDate,
//         };
//         console.log("Selected Date Range:", startDate);
//         console.log("Selected Date Range:", endDate);

//         console.log("Selected Date Range:", selectedDates);
//         console.log("Advanced Filter Date Range:", advancedFilterDateRange);

//         // Call getCPCDateRange with the required parameters
//         this.getCPCDateRange(dateRangeLabel, startDate, endDate);

//         // Continue with other function calls
//         this.getSalesDateRange(dateRangeLabel, startDate, endDate);
//         this.getBudgetDateRange(dateRangeLabel, startDate, endDate);
//         this.getSpendChartData(dateRangeLabel, startDate, endDate);

//         this.getOrderDateRange(dateRangeLabel, startDate, endDate);
//         this.getChartRoyaltiesDistributionData(
//           dateRangeLabel,
//           startDate,
//           endDate
//         );
//         this.getChartRoyaltiesProjectedData(dateRangeLabel, startDate, endDate);
//         this.getCR();
//       }
//     } catch (error) {
//       console.error("Error in onChangeDateRangeSubmitGlobal:", error);
//     }
//   };

//   getCPCDateRange = async (dateRangeLabel, startDate, endDate) => {
//     try {
//       this.setState({
//         CPCValue: (
//           <Spinner animation="border" role="status">
//             <span className="visually-hidden">Loading...</span>
//           </Spinner>
//         ),
//       });
//       let userToken = localStorage.getItem("userToken");
//       let AuthToken = "Bearer " + userToken;
//       const responseCPC = await fetch(
//         "https://api.aimosa.io/Dashboard/Metrics",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: AuthToken,
//           },
//           body: JSON.stringify({
//             globalFilters: {
//               searchText: "",
//               authors: [],
//               bookFormats: [],
//               dateRanges: {
//                 dateRange: dateRangeLabel === "OK" ? "Custom" : dateRangeLabel,
//                 startDate: startDate,
//                 endDate: endDate,
//               },

//               titles: this.state.titleDataList,
//               campaigns: [],
//               platforms: [],
//               marketPlaces: [],
//               adTypes: [],
//               advancedFilters: [],
//             },
//             metricName: "CPC",
//           }),
//         }
//       );

//       const responceCPCData = await responseCPC.json();
//       this.setState({ CPCAvgValue: responceCPCData.result[0].avgvalue });
//       this.setState({ CPCMetricName: responceCPCData.result[0].matricName });
//       if (this.state.CPCValue !== undefined && this.state.CPCValue !== null) {
//         this.setState({ CPCValue: responceCPCData.result[0].value.toFixed(3) });
//       }
//     } catch (error) {
//       console.error(error);
//       return error;
//     }
//     console.log(startDate);
//   };
//   getBudgetDateRange = async (dateRangeLabel, startDate, endDate) => {
//     try {
//       this.setState({
//         budgetValue: (
//           <Spinner animation="border" role="status">
//             <span className="visually-hidden">Loading...</span>
//           </Spinner>
//         ),
//       });
//       let userToken = localStorage.getItem("userToken");
//       let AuthToken = "Bearer " + userToken;
//       const responseBudget = await fetch(
//         "https://api.aimosa.io/Dashboard/Metrics",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: AuthToken,
//           },
//           body: JSON.stringify({
//             globalFilters: {
//               searchText: "",
//               authors: [],
//               bookFormats: [],
//               dateRanges: {
//                 dateRange: dateRangeLabel === "OK" ? "Custom" : dateRangeLabel,
//                 startDate: startDate,
//                 endDate: endDate,
//               },
//               titles: this.state.titleDataList,
//               campaigns: [],
//               platforms: [],
//               marketPlaces: [],
//               adTypes: [],
//               advancedFilters: [],
//             },
//             metricName: "Budget",
//           }),
//         }
//       );
//       const responseBudgetData = await responseBudget.json();
//       // console.log("budget==", responseBudgetData.result);
//       this.setState({ budgetAvgValue: responseBudgetData.result[0].avgvalue });
//       this.setState({
//         budgetMetricName: responseBudgetData.result[0].matricName,
//       });
//       if (this.state.CPCValue !== undefined && this.state.CPCValue !== null) {
//         this.setState({
//           budgetValue: responseBudgetData.result[0].value.toFixed(3),
//         });
//       }
//     } catch (error) {
//       console.error(error); // You might send an exception to your error tracker like AppSignal
//       return error;
//     }
//   };

//   getSalesDateRange = async (dateRangeLabel, startDate, endDate) => {
//     try {
//       this.setState({
//         salesValue: (
//           <Spinner animation="border" role="status">
//             <span className="visually-hidden">Loading...</span>
//           </Spinner>
//         ),
//       });

//       let userToken = localStorage.getItem("userToken");
//       let AuthToken = "Bearer " + userToken;

//       const responseSales = await fetch(
//         "https://api.aimosa.io/Dashboard/Metrics",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: AuthToken,
//           },
//           body: JSON.stringify({
//             globalFilters: {
//               searchText: "",
//               authors: [],
//               bookFormats: [],
//               dateRanges: {
//                 dateRange: dateRangeLabel === "OK" ? "Custom" : dateRangeLabel,
//                 startDate: startDate,
//                 endDate: endDate,
//               },
//               titles: this.state.titleDataList,
//               campaigns: [],
//               platforms: [],
//               marketPlaces: [],
//               adTypes: [],
//               advancedFilters: [],
//             },
//             metricName: "Sales",
//           }),
//         }
//       );

//       const responceSalesData = await responseSales.json();
//       // console.log("sale=", responceSalesData.result);

//       this.setState({ salesAvgValue: responceSalesData.result[0].avgvalue });
//       this.setState({
//         salesMetricName: responceSalesData.result[0].metricName,
//       });

//       if (
//         responceSalesData.result[0].value ||
//         responceSalesData.result[0].value === 0
//       ) {
//         this.setState({
//           salesValue: responceSalesData.result[0].value.toFixed(3),
//         });
//       }
//       // console.log("data", salesValue);
//     } catch (error) {
//       console.error(error);
//       return error;
//     }
//   };

//   getOrderDateRange = async (dateRangeLabel, startDate, endDate) => {
//     try {
//       this.setState({
//         orderValue: (
//           <Spinner animation="border" role="status">
//             <span className="visually-hidden">Loading...</span>
//           </Spinner>
//         ),
//       });

//       let userToken = localStorage.getItem("userToken");
//       let AuthToken = "Bearer " + userToken;

//       const responseOrder = await fetch(
//         "https://api.aimosa.io/Dashboard/Metrics",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: AuthToken,
//           },
//           body: JSON.stringify({
//             globalFilters: {
//               searchText: "",
//               authors: [],
//               bookFormats: [],
//               dateRanges: {
//                 dateRange: dateRangeLabel === "OK" ? "Custom" : dateRangeLabel,
//                 startDate: startDate,
//                 endDate: endDate,
//               },
//               titles: this.state.titleDataList,
//               campaigns: [],
//               platforms: [],
//               marketPlaces: [],
//               adTypes: [],
//               advancedFilters: [],
//             },
//             metricName: "Order",
//           }),
//         }
//       );

//       const responseOrderData = await responseOrder.json();
//       console.log("order=", responseOrderData.result);
//       console.log("orderavg=" + responseOrderData.result[0].value);

//       this.setState({ orderAvgValue: responseOrderData.result[0].avgvalue });
//       this.setState({
//         orderMetricName: responseOrderData.result[0].matricName,
//       });

//       if (
//         responseOrderData.result[0].value ||
//         responseOrderData.result[0].value === 0
//       ) {
//         this.setState({
//           orderValue: responseOrderData.result[0].value,
//         });
//       }
//     } catch (error) {
//       console.error(error);
//       return error;
//     }
//   };

//   selectPlan = (eve) => {
//     const selectedPlan: any = document.querySelectorAll(".filterBtnClick");
//     if (selectedPlan.length > 0) {
//       const clearEle: any = document.querySelectorAll(
//         ".filterBtnClick.selected"
//       );
//       for (var i = 0; i < clearEle.length; i++) {
//         clearEle[i].classList.remove("selected");
//       }
//       for (let i = 0; i < selectedPlan.length; i++) {
//         if (selectedPlan[i].contains(eve.target)) {
//           selectedPlan[i].classList.add("selected");
//         }
//       }
//     }
//   };

//   cancelDropdown = () => {
//     const eleRm: any = document.getElementsByClassName("dropdown-menu");
//     for (let i = 0; i < eleRm.length; i++) {
//       eleRm[i].classList.remove("show");
//     }
//   };
//   handleSyncDataClose = () => {
//     localStorage.setItem("lastDisplayedTime", new Date().getTime().toString());
//     this.setState({ isSyncDataVisible: false });
//   };

//   titleHandler = async () => {
//     try {
//       this.setState({
//         orderValue: (
//           <Spinner animation="border" role="status">
//             <span className="visually-hidden">Loading...</span>
//           </Spinner>
//         ),
//       });

//       let userToken = localStorage.getItem("userToken");
//       let AuthToken = "Bearer " + userToken;

//       const responseOrder = await fetch(
//         `https://api.aimosa.io/Dashboard/GlobalFilterValue?SearchText`,
//         {
//           method: "GET",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: AuthToken,
//           },
//         }
//       );

//       const responseOrderData = await responseOrder.json();

//       console.log(responseOrderData);

//       this.setState({
//         titleData: responseOrderData.result.title,
//         filteredTitleData: responseOrderData.result.title,
//       });
//       this.setState({
//         campainData: responseOrderData.result.campaginName,
//         filteredcampainData: responseOrderData.result.campaginName,
//       });
//       this.setState({
//         authorData: responseOrderData.result.author,
//         filteredauthorData: responseOrderData.result.author,
//       });
//     } catch (error) {
//       console.error(error);
//       return error;
//     }
//   };
//   applyFilter = () => {
//     this.setState({ titleDataList: [] });
//     this.setState({ authorDataList: [] });
//     this.setState({ campainDataList: [] });
//     this.setState({ marketDataList: [] });
//     this.setState({ bookDataList: [] });
//     this.setState({ issearchText: "" });
//     this.componentDidMount();
//     this.cancelDropdown();
//   };

//   titleHandlerList = () => {
//     this.setState({ ispopup: !this.state.ispopup });
//   };
//   authorHandlerList = () => {
//     this.setState({ ispopupauthor: !this.state.ispopupauthor });
//   };
//   campainHandlerList = () => {
//     this.setState({ ispoupcampain: !this.state.ispoupcampain });
//   };
//   bookFormatHandlerList = () => {
//     this.setState({ ispopupbookformat: !this.state.ispopupbookformat });
//   };
//   marketplaceHandlerList = () => {
//     this.setState({ ispopupmarketplace: !this.state.ispopupmarketplace });
//   };
//   markettextChangeHandler = (event) => {
//     const searchText = event.target.value;
//     this.setState({
//       issearchText: { ...this.state.issearchText, marketsearch: searchText },
//     });
//     console.log(searchText);
//     const filteredTitles = this.state.marketData.filter((title: any) =>
//       title.toLowerCase().includes(searchText.toLowerCase())
//     );
//     this.setState({ filteredmarketData: filteredTitles });
//   };
//   marketselectallHandler = () => {};
//   marketHandlerList = () => {
//     this.setState({ ispopupmarket: !this.state.ispopupmarket });
//     console.log(!this.state.ispopupmarket);
//   };
//   textChangeHandler = (type, event) => {
//     if (type === "author") {
//       const searchText = event.target.value;
//       this.setState({
//         issearchText: { ...this.state.issearchText, authorsearch: searchText },
//       });
//       console.log(this.state.issearchText);
//       const filteredTitles = this.state.authorData.filter((title: any) =>
//         title.toLowerCase().includes(searchText.toLowerCase())
//       );
//       this.setState({ filteredauthorData: filteredTitles });
//     } else if (type === "book") {
//       const searchText = event.target.value;
//       this.setState({
//         issearchText: { ...this.state.issearchText, booksearch: searchText },
//       });
//       console.log(this.state.issearchText);
//       const filteredTitles = this.state.bookData.filter((title: any) =>
//         title.toLowerCase().includes(searchText.toLowerCase())
//       );
//       this.setState({ filteredbookData: filteredTitles });
//     } else if (type === "campain") {
//       const searchText = event.target.value;
//       this.setState({
//         issearchText: { ...this.state.issearchText, campainsearch: searchText },
//       });
//       console.log(this.state.issearchText);
//       const filteredTitles = this.state.campainData.filter((title: any) =>
//         title.toLowerCase().includes(searchText.toLowerCase())
//       );
//       this.setState({ filteredcampainData: filteredTitles });
//     } else {
//       const searchText = event.target.value;
//       this.setState({
//         issearchText: { ...this.state.issearchText, titlesearch: searchText },
//       });
//       console.log(this.state.issearchText);
//       const filteredTitles = this.state.titleData.filter((title: any) =>
//         title.toLowerCase().includes(searchText.toLowerCase())
//       );
//       this.setState({ filteredTitleData: filteredTitles });
//     }
//   };
//   searchHandler = (event) => {
//     if (event.key === "Enter") {
//       event.preventDefault();
//     }
//   };
//   checkboxHandler = (type, id: string) => {
//     if (type === "title") {
//       let list: string[] = [...this.state.titleDataList];
//       if (list.includes(id)) {
//         const updatedList = list.filter((item) => item !== id);
//         this.setState({ titleDataList: updatedList });
//       } else {
//         list.push(id);
//         this.setState({ titleDataList: list });
//       }
//     } else if (type === "market") {
//       let list: string[] = [...this.state.marketDataList];
//       if (list.includes(id)) {
//         const updatedList = list.filter((item) => item !== id);
//         this.setState({ marketDataList: updatedList });
//       } else {
//         list.push(id);
//         this.setState({ marketDataList: list });
//       }
//     } else if (type === "author") {
//       let list: string[] = [...this.state.authorDataList];
//       if (list.includes(id)) {
//         const updatedList = list.filter((item) => item !== id);
//         this.setState({ authorDataList: updatedList });
//       } else {
//         list.push(id);
//         this.setState({ authorDataList: list });
//       }
//     } else if (type === "book") {
//       let list: string[] = [...this.state.bookDataList];
//       if (list.includes(id)) {
//         const updatedList = list.filter((item) => item !== id);
//         this.setState({ bookDataList: updatedList });
//       } else {
//         list.push(id);
//         this.setState({ bookDataList: list });
//       }
//     } else {
//       let list: string[] = [...this.state.campainDataList];
//       if (list.includes(id)) {
//         const updatedList = list.filter((item) => item !== id);
//         this.setState({ campainDataList: updatedList });
//       } else {
//         list.push(id);
//         this.setState({ campainDataList: list });
//       }
//     }
//   };

//   selectallHandler = (type: any) => {
//     if (type === "title") {
//       const { titleData, titleDataList } = this.state;
//       const allTitlesSelected = titleDataList.length === titleData.length;
//       if (allTitlesSelected) {
//         this.setState({ titleDataList: [] });
//       } else {
//         this.setState({ titleDataList: [...titleData] });
//       }
//     } else if (type === "market") {
//       const { marketData, marketDataList } = this.state;
//       const allTitlesSelected = marketDataList.length === marketData.length;
//       if (allTitlesSelected) {
//         this.setState({ marketDataList: [] });
//       } else {
//         this.setState({ marketDataList: [...marketData] });
//       }
//     } else if (type === "author") {
//       const { authorData, authorDataList } = this.state;
//       const allTitlesSelected = authorDataList.length === authorData.length;
//       if (allTitlesSelected) {
//         this.setState({ authorDataList: [] });
//       } else {
//         this.setState({ authorDataList: [...authorData] });
//       }
//     } else if (type === "book") {
//       const { bookData, bookDataList } = this.state;
//       const allTitlesSelected = bookDataList.length === bookData.length;
//       if (allTitlesSelected) {
//         this.setState({ bookDataList: [] });
//       } else {
//         this.setState({ bookDataList: [...bookData] });
//       }
//     } else if (type === "campain") {
//       const { campainData, campainDataList } = this.state;
//       const allTitlesSelected = campainDataList.length === campainData.length;
//       if (allTitlesSelected) {
//         this.setState({ campainDataList: [] });
//       } else {
//         this.setState({ campainDataList: [...campainData] });
//       }
//     }
//   };

//   handleMetricChange = (selectedMetricName: string, metricType: string) => {
//     const selectedMetric = this.state.metrics.find(
//       (metric) => metric.metricName === selectedMetricName
//     );

//     if (selectedMetric) {
//       const newState = {
//         [`selectedMetric${metricType}`]: {
//           metricName: selectedMetric.metricName,
//           value: selectedMetric.value,
//           avgvalue: selectedMetric.avgvalue,
//         },
//       };

//       this.setState(newState);
//       console.log("Selected Metric Name:", selectedMetricName);
//       console.log(selectedMetric);
//     }
//   };

//   getMetaData = async () => {
//     try {
//       const userToken = localStorage.getItem("userToken");
//       const authToken = "Bearer " + userToken;
//       const url = "https://api.aimosa.io/MasterData/meta";
//       const response = await fetch(url, {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: authToken,
//         },
//       });

//       if (!response.ok) {
//         console.error(`Error: ${response.status} - ${response.statusText}`);
//         return;
//       }

//       const responseData = await response.json();
//       const bookName = responseData.result?.data?.bookFormats.map(
//         (item: any) => item.name
//       );
//       const marketName = responseData.result?.data?.marketplaces.map(
//         (item: any) => item.name
//       );
//       // const marketplaces: Marketplace[] =
//       //   responseData?.result?.data?.marketplaces || [];
//       // const bookFormats: BookFormat[] =
//       //   responseData?.result?.data?.bookFormats || [];
//       this.setState({
//         marketData: marketName,
//         filteredmarketData: marketName,
//       });
//       this.setState({
//         bookData: bookName,
//         filteredbookData: bookName,
//       });

//       // this.setState({
//       //   authorData: responseOrderData.result.author,
//       //   filteredauthorData: responseOrderData.result.author,
//       // });
//       // this.setState({
//       //   metaData: {
//       //     data: { marketplaces, bookFormats },
//       //   },
//       //   bookFormats: bookFormats,
//       // });
//       // console.log("Book Formats:", bookFormats);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   render() {
//     const { marketplaces, bookFormats } = this.state.metaData.data;
//     console.log(this.state.titleDataList);
//     const titleList = this.state.filteredTitleData.map((item, index) => {
//       return (
//         <ul className="p-0 m-1" key={index}>
//           <div className="d-flex align-items-center">
//             <input
//               type="checkbox"
//               id={item}
//               checked={this.state.titleDataList.includes(item)}
//               // className="form-check-input mx-5"
//               className="mx-2 filter-checkbox"
//               onChange={() => this.checkboxHandler("title", item)}
//               onClick={(e) => {
//                 e.stopPropagation();
//               }}
//             />
//             <Tooltip
//               placement="top-start"
//               title={
//                 <React.Fragment>
//                   <div>{item}</div>
//                 </React.Fragment>
//               }
//             >
//               <li className="titleList-li">{item}</li>
//             </Tooltip>
//           </div>
//           <div className="border-bottom" style={{ margin: "0px 10px" }}></div>
//         </ul>
//       );
//     });
//     const marketPlaceList = this.state.filteredmarketData.map((item, index) => {
//       return (
//         <ul className="p-0 m-0" key={index}>
//           <div className="d-flex align-items-center">
//             <input
//               type="checkbox"
//               className="mx-2 filter-checkbox"
//               checked={this.state.marketDataList.includes(item)}
//               onChange={() => this.checkboxHandler("market", item)}
//               onClick={(e) => {
//                 e.stopPropagation();
//               }}
//             />
//             <Tooltip
//               placement="top-start"
//               title={
//                 <React.Fragment>
//                   <div>{item}</div>
//                 </React.Fragment>
//               }
//             >
//               <li className="titleList-li">{item}</li>
//             </Tooltip>
//           </div>
//         </ul>
//       );
//     });

//     const authorList = this.state.filteredauthorData.map((item, index) => {
//       return (
//         <ul className="p-0 m-0" key={index}>
//           <div className="d-flex align-items-center">
//             <input
//               type="checkbox"
//               className="mx-2 filter-checkbox"
//               checked={this.state.authorDataList.includes(item)}
//               onChange={() => this.checkboxHandler("author", item)}
//               onClick={(e) => {
//                 e.stopPropagation();
//               }}
//             />
//             <Tooltip
//               placement="top-start"
//               title={
//                 <React.Fragment>
//                   <div>{item}</div>
//                 </React.Fragment>
//               }
//             >
//               <li className="titleList-li">{item}</li>
//             </Tooltip>
//           </div>
//         </ul>
//       );
//     });
//     const bookFormatList = this.state.filteredbookData.map((item, index) => {
//       return (
//         <ul className="p-0 m-0" key={index}>
//           <div className="d-flex align-items-center">
//             <input
//               type="checkbox"
//               className="mx-2 filter-checkbox"
//               checked={this.state.bookDataList.includes(item)}
//               onChange={() => this.checkboxHandler("book", item)}
//               onClick={(e) => {
//                 e.stopPropagation();
//               }}
//             />
//             <Tooltip
//               placement="top-start"
//               title={
//                 <React.Fragment>
//                   <div>{item}</div>
//                 </React.Fragment>
//               }
//             >
//               <li className="titleList-li">{item}</li>
//             </Tooltip>
//           </div>
//         </ul>
//       );
//     });
//     // const bookFormatList = (this.state.bookFormats as BookFormat[]).map(
//     //   (format, index) => {
//     //     return (
//     //       <ul className="p-0 m-0" key={index}>
//     //         <div className="d-flex align-items-center">
//     //           <input
//     //             type="checkbox"
//     //             className="mx-2 filter-checkbox"
//     //             onClick={(e) => {
//     //               e.stopPropagation();
//     //             }}
//     //           />
//     //           <Tooltip
//     //             placement="top-start"
//     //             title={
//     //               <React.Fragment>
//     //                 <div>{format.name}</div>
//     //               </React.Fragment>
//     //             }
//     //           >
//     //             <li className="titleList-li">{format.name}</li>
//     //           </Tooltip>
//     //         </div>
//     //       </ul>
//     //     );
//     //   }
//     // );
//     // const bookFormatList = (this.state.bookFormats as BookFormat[]).map(
//     //   (format, index) => {
//     //     return (
//     //       <ul className="p-0 m-0" key={index}>
//     //         <div className="d-flex align-items-center">
//     //           <input
//     //             type="checkbox"
//     //             className="mx-2 filter-checkbox"
//     //             onClick={(e) => {
//     //               e.stopPropagation();
//     //             }}
//     //           />
//     //           <Tooltip
//     //             placement="top-start"
//     //             title={
//     //               <React.Fragment>
//     //                 <div>{format.name}</div>
//     //               </React.Fragment>
//     //             }
//     //           >
//     //             <li className="titleList-li">{format.name}</li>
//     //           </Tooltip>
//     //         </div>
//     //       </ul>
//     //     );
//     //   }
//     // );
//     const campainplaceList = this.state.filteredcampainData.map(
//       (item, index) => (
//         <ul className="p-0 m-0" key={index}>
//           <div className="d-flex align-items-center">
//             <input
//               type="checkbox"
//               checked={this.state.campainDataList.includes(item)}
//               onChange={() => this.checkboxHandler("campain", item)}
//               className="mx-2 filter-checkbox"
//               onClick={(e) => {
//                 e.stopPropagation();
//               }}
//             />
//             <Tooltip
//               placement="top-start"
//               title={
//                 <React.Fragment>
//                   <div>{item}</div>
//                 </React.Fragment>
//               }
//             >
//               <li className="titleList-li">{item}</li>
//             </Tooltip>
//           </div>
//         </ul>
//       )
//     );
//     return (
//       <DashboardLayout>
//         <div className="main-cont-header dashboard-container">
//           <Row className="page-header">
//             <Col>
//               <Row>
//                 <Col md={8} style={{ display: "flex" }}>
//                   <div className="input-group calender">
//                     <span
//                       className="input-group-text"
//                       id="inputGroup-sizing-sm"
//                     >
//                       <img src={CalenderIcon} alt="calender icon" />

//                       {/* <input
//                       type="date"
//                       className="form-control"
//                       placeholder="Select a timeline"
//                       aria-label="Username"
//                       aria-describedby="addon-wrapping"
//                       onChange={this.dateTimeLine}
//                     /> */}
//                       <DateRangePicker
//                         placeholder="Select a timeline"
//                         onChange={this.onChangeDateRangeSubmitGlobal}
//                         format="yyyy-MM-dd"
//                         disabledDate={(date) => isAfter(date, new Date())}
//                         defaultValue={[this.startDate, new Date()]}
//                         ranges={[
//                           {
//                             label: "Yesterday",
//                             value: [
//                               moment()
//                                 .subtract(1, "days")
//                                 .startOf("day")
//                                 .toDate(),
//                               moment()
//                                 .subtract(1, "days")
//                                 .endOf("day")
//                                 .toDate(),
//                             ],
//                           },
//                           {
//                             label: "Last7Days",
//                             value: [
//                               moment()
//                                 .subtract(6, "days")
//                                 .startOf("day")
//                                 .toDate(),
//                               new Date(),
//                             ],
//                           },
//                           {
//                             label: "Last14Days",
//                             value: [
//                               moment()
//                                 .subtract(13, "days")
//                                 .startOf("day")
//                                 .toDate(),
//                               new Date(),
//                             ],
//                           },
//                           {
//                             label: "1 Month",
//                             value: [
//                               moment()
//                                 .subtract(29, "days")
//                                 .startOf("day")
//                                 .toDate(),
//                               new Date(),
//                             ],
//                           },
//                         ]}
//                       />
//                     </span>
//                   </div>
//                   <div className="filter-item filter-link-container dropdownContent global-dashboard-filter">
//                     <p
//                       id="dropdownMenuButton1"
//                       data-bs-toggle="dropdown"
//                       data-bs-auto-close="outside"
//                       aria-expanded="false"
//                       data-bs-display="static"
//                     >
//                       <i>
//                         <img src={FilterIcon} alt="filter icon" />
//                       </i>
//                       <span>Filter</span>
//                       <i
//                         className="fa fa-angle-down down-arrow-right"
//                         aria-hidden="true"
//                       ></i>
//                     </p>
//                     <div
//                       className="dropdown-menu dropdown-menu-lg-start"
//                       aria-labelledby="dropdownMenuButton1"
//                     >
//                       <h6 className="modelText">Filter</h6>
//                       <Row>
//                         <Col md={6}>
//                           <label>Title</label>
//                           <div
//                             className="Title-title"
//                             onClick={this.titleHandlerList}
//                           >
//                             select one
//                             {this.state.ispopup ? (
//                               <div className="list-title">
//                                 <form
//                                   className="filter-search"
//                                   style={{
//                                     position: "sticky",
//                                     top: "0",
//                                   }}
//                                 >
//                                   <div
//                                     style={{
//                                       position: "relative",
//                                       backgroundColor: "white",
//                                     }}
//                                   >
//                                     <input
//                                       type="text"
//                                       placeholder="Search title"
//                                       name="search"
//                                       value={
//                                         this.state.issearchText.titlesearch
//                                       }
//                                       onChange={(e) =>
//                                         this.textChangeHandler("title", e)
//                                       }
//                                       className="input-control"
//                                       onClick={(e) => {
//                                         e.stopPropagation();
//                                         this.searchHandler(e);
//                                       }}
//                                     />
//                                     <div className="fa-search-title">
//                                       <i className="fa fa-search icon-color"></i>
//                                     </div>
//                                   </div>
//                                 </form>
//                                 <div className="d-flex align-items-center">
//                                   <input
//                                     type="checkbox"
//                                     className="filter-checkbox checkbox-adjust"
//                                     onChange={() =>
//                                       this.selectallHandler("title")
//                                     }
//                                     checked={
//                                       0 < this.state.titleDataList.length
//                                         ? this.state.titleData.length ===
//                                           this.state.titleDataList.length
//                                         : false
//                                     }
//                                     onClick={(e) => {
//                                       e.stopPropagation();
//                                     }}
//                                   />

//                                   <div>All Titles</div>
//                                 </div>
//                                 {titleList}
//                               </div>
//                             ) : null}
//                           </div>
//                         </Col>
//                         <Col md={6}>
//                           <div>
//                             <label htmlFor="marketplace">Marketplace</label>
//                             <div
//                               className="Title-title"
//                               onClick={this.marketplaceHandlerList}
//                             >
//                               {this.state.selectedMarketplace ? (
//                                 <span>{this.state.selectedMarketplace}</span>
//                               ) : (
//                                 <span>Select one</span>
//                               )}
//                               {this.state.ispopupmarketplace && (
//                                 <div className="list-title">
//                                   <form
//                                     className="filter-search"
//                                     style={{
//                                       position: "sticky",
//                                       top: "0",
//                                     }}
//                                   >
//                                     <div
//                                       style={{
//                                         position: "relative",
//                                         backgroundColor: "white",
//                                       }}
//                                     >
//                                       <input
//                                         type="text"
//                                         placeholder="Search title"
//                                         name="search"
//                                         value={
//                                           this.state.issearchText.marketsearch
//                                         }
//                                         onChange={this.markettextChangeHandler}
//                                         className="input-control"
//                                         onClick={(e) => {
//                                           e.stopPropagation();
//                                           this.searchHandler(e);
//                                         }}
//                                       />
//                                       <div className="fa-search-title">
//                                         <i className="fa fa-search icon-color"></i>
//                                       </div>
//                                     </div>
//                                   </form>
//                                   <div className="d-flex align-items-center">
//                                     <input
//                                       type="checkbox"
//                                       className="filter-checkbox checkbox-adjust"
//                                       checked={
//                                         0 < this.state.marketDataList.length
//                                           ? this.state.marketData.length ===
//                                             this.state.marketDataList.length
//                                           : false
//                                       }
//                                       onChange={() =>
//                                         this.selectallHandler("market")
//                                       }
//                                       onClick={(e) => {
//                                         e.stopPropagation();
//                                       }}
//                                     />
//                                     <div>All Titles</div>
//                                   </div>
//                                   {marketPlaceList}
//                                 </div>
//                               )}
//                             </div>
//                           </div>
//                         </Col>
//                       </Row>
//                       <Row>
//                         <Col md={6}>
//                           <label>Author</label>
//                           <div
//                             className="Title-title"
//                             onClick={this.authorHandlerList}
//                           >
//                             select one
//                             {this.state.ispopupauthor ? (
//                               <div className="list-title">
//                                 <form
//                                   className="filter-search"
//                                   style={{
//                                     position: "sticky",
//                                     top: "0",
//                                   }}
//                                 >
//                                   <div
//                                     style={{
//                                       position: "relative",
//                                       backgroundColor: "white",
//                                     }}
//                                   >
//                                     <input
//                                       type="text"
//                                       placeholder="Search title"
//                                       name="search"
//                                       value={
//                                         this.state.issearchText.authorsearch
//                                       }
//                                       onChange={(e) =>
//                                         this.textChangeHandler("author", e)
//                                       }
//                                       className="input-control"
//                                       onClick={(e) => {
//                                         e.stopPropagation();
//                                         this.searchHandler(e);
//                                       }}
//                                     />
//                                     <div className="fa-search-title">
//                                       <i className="fa fa-search icon-color"></i>
//                                     </div>
//                                   </div>
//                                 </form>
//                                 <div className="d-flex align-items-center">
//                                   <input
//                                     type="checkbox"
//                                     className="filter-checkbox checkbox-adjust"
//                                     checked={
//                                       0 < this.state.authorDataList.length
//                                         ? this.state.authorData.length ===
//                                           this.state.authorDataList.length
//                                         : false
//                                     }
//                                     onChange={() =>
//                                       this.selectallHandler("author")
//                                     }
//                                     onClick={(e) => {
//                                       e.stopPropagation();
//                                     }}
//                                   />
//                                   <div>All Titles</div>
//                                 </div>
//                                 {authorList}
//                               </div>
//                             ) : null}
//                           </div>
//                         </Col>
//                         <Col md={6}>
//                           <label htmlFor="bookFormat">Book Format</label>
//                           <div
//                             className="Title-title"
//                             onClick={this.bookFormatHandlerList}
//                           >
//                             {this.state.selectedBookFormat ? (
//                               <span>{this.state.selectedBookFormat}</span>
//                             ) : (
//                               <span>Select one</span>
//                             )}
//                             {this.state.ispopupbookformat && (
//                               <div className="list-title">
//                                 <form
//                                   className="filter-search"
//                                   style={{
//                                     position: "sticky",
//                                     top: "0",
//                                   }}
//                                 >
//                                   <div
//                                     style={{
//                                       position: "relative",
//                                       backgroundColor: "white",
//                                     }}
//                                   >
//                                     <input
//                                       type="text"
//                                       placeholder="Search title"
//                                       name="search"
//                                       value={this.state.issearchText.booksearch}
//                                       onChange={(e) =>
//                                         this.textChangeHandler("book", e)
//                                       }
//                                       className="input-control"
//                                       onClick={(e) => {
//                                         e.stopPropagation();
//                                         this.searchHandler(e);
//                                       }}
//                                     />
//                                     <div className="fa-search-title">
//                                       <i className="fa fa-search icon-color"></i>
//                                     </div>
//                                   </div>
//                                 </form>
//                                 <div className="d-flex align-items-center">
//                                   <input
//                                     type="checkbox"
//                                     className="filter-checkbox checkbox-adjust"
//                                     checked={
//                                       0 < this.state.bookDataList.length
//                                         ? this.state.bookData.length ===
//                                           this.state.bookDataList.length
//                                         : false
//                                     }
//                                     onChange={() =>
//                                       this.selectallHandler("book")
//                                     }
//                                     onClick={(e) => {
//                                       e.stopPropagation();
//                                     }}
//                                   />
//                                   <div>All Titles</div>
//                                 </div>
//                                 {bookFormatList}
//                               </div>
//                             )}
//                           </div>
//                         </Col>
//                       </Row>

//                       <Row>
//                         <Col md={6}>
//                           <label>Ad Type</label>
//                           <div className="adTypeButtons">
//                             <button
//                               className="filterBtnClick"
//                               onClick={this.selectPlan}
//                             >
//                               All Type
//                             </button>
//                             <button
//                               className="filterBtnClick"
//                               onClick={this.selectPlan}
//                             >
//                               Auto
//                             </button>
//                             <button
//                               className="filterBtnClick"
//                               onClick={this.selectPlan}
//                             >
//                               Manual
//                             </button>
//                           </div>
//                         </Col>
//                         <Col md={6}>
//                           <label>Campaign</label>
//                           <div
//                             className="Title-title"
//                             onClick={this.campainHandlerList}
//                           >
//                             {this.state.selectedMarketplace ? (
//                               <span>{this.state.selectedMarketplace}</span>
//                             ) : (
//                               <span>Select one</span>
//                             )}
//                             {this.state.ispoupcampain && (
//                               <div className="list-title">
//                                 <form
//                                   className="filter-search"
//                                   style={{
//                                     position: "sticky",
//                                     top: "0",
//                                   }}
//                                 >
//                                   <div
//                                     style={{
//                                       position: "relative",
//                                       backgroundColor: "white",
//                                     }}
//                                   >
//                                     <input
//                                       type="text"
//                                       placeholder="Search title"
//                                       name="search"
//                                       value={
//                                         this.state.issearchText.campainsearch
//                                       }
//                                       onChange={(e) =>
//                                         this.textChangeHandler("campain", e)
//                                       }
//                                       className="input-control"
//                                       onClick={(e) => {
//                                         e.stopPropagation();
//                                         this.searchHandler(e);
//                                       }}
//                                     />
//                                     <div className="fa-search-title">
//                                       <i className="fa fa-search icon-color"></i>
//                                     </div>
//                                   </div>
//                                 </form>
//                                 <div className="d-flex align-items-center">
//                                   <input
//                                     type="checkbox"
//                                     className="filter-checkbox checkbox-adjust"
//                                     checked={
//                                       0 < this.state.campainDataList.length
//                                         ? this.state.campainData.length ===
//                                           this.state.campainDataList.length
//                                         : false
//                                     }
//                                     onChange={() =>
//                                       this.selectallHandler("campain")
//                                     }
//                                     onClick={(e) => {
//                                       e.stopPropagation();
//                                     }}
//                                   />
//                                   <div>All Titles</div>
//                                 </div>
//                                 {campainplaceList}
//                               </div>
//                             )}
//                           </div>
//                         </Col>
//                       </Row>
//                       {/* <Row>
//                         <Col md={6}>
//                           <label>Platform</label>
//                           <select className="form-control">
//                             <option>select one</option>
//                           </select>
//                         </Col>
//                         <div className="col-md-6"></div>
//                       </Row> */}
//                       <br />
//                       <Row>
//                         <Col md={12}>
//                           <div className="filterModelBottomButtoms">
//                             <button
//                               className="filterModelCancelBtn"
//                               onClick={this.cancelDropdown}
//                             >
//                               Cancel
//                             </button>
//                             <button
//                               className="filterModelApplyFilterBtn"
//                               onClick={this.applyFilter}
//                             >
//                               Apply Filter
//                             </button>
//                           </div>
//                         </Col>
//                       </Row>
//                     </div>
//                   </div>
//                 </Col>
//               </Row>
//             </Col>
//             <Col className="text-end last-sync">
//               <span>
//                 <i>
//                   <img
//                     src={LinkIcon}
//                     alt="refresh icon"
//                     onClick={this.lastAppSyncClick}
//                     style={{ cursor: "pointer" }}
//                   />
//                 </i>
//                 Last App Sync :
//               </span>
//               <span className="time-summery">
//                 {" "}
//                 {this.state.lastUpdatedDate || "Not available"}
//               </span>
//             </Col>
//             <Modal
//               visible={this.state.isModalVisible}
//               onCancel={this.handleModalClose}
//               footer={null}
//             >
//               <Synckdpextension />
//             </Modal>
//           </Row>
//           <Row className="mt-5">
//             <Col className="dashoard-nav mt-4 mb-4">
//               <Link to={""}>Dashboard</Link> {">"} Default
//             </Col>
//             {/* <Col>
//               <div className="d-flex justify-content-end header-actions mt-4">
//                 <div className="text-green px-3">
//                   <i>
//                     <img src={AddIcon} alt="add icon" />
//                   </i>{" "}
//                   Add
//                 </div>
//                 <div className="px-3">
//                   <i>
//                     <img src={ResetIcon} alt="reset  icon" />
//                   </i>{" "}
//                   Reset
//                 </div>
//                 <div className="px-3">
//                   <i>
//                     <img src={ShareIcon} alt="share icon" />
//                   </i>{" "}
//                   Share
//                 </div>
//               </div>
//             </Col> */}
//           </Row>
//         </div>
//         <br />
//         <div className="sync-data-container margin-bottom-20">
//           {this.state.isSyncDataVisible && (
//             <SyncDataDaily onClose={this.handleSyncDataClose} />
//           )}
//         </div>

//         <div className="dashboard-container dashboard-page padding-lr-30">
//           <Row>
//             <Col md={6}>
//               <Row>
//                 <Col md={6}>
//                   <Row>
//                     <Col md={6}>
//                       <div className="widget-container height-220">
//                         <div className="widget-header mb-4">
//                           <Row>
//                             <Col md={9} className="widget-select-container">
//                               <div className="input-group filter">
//                                 <select
//                                   defaultValue={"DEFAULT"}
//                                   className="form-select widget-select"
//                                   id="inputGroupSelect02"
//                                 >
//                                   <option value="DEFAULT">Select</option>
//                                   {/* <option value="1">One</option>
//                                   <option value="2">Two</option>
//                                   <option value="3">Three</option> */}
//                                 </select>
//                                 <span>
//                                   <img
//                                     src={InfoCircleIcon}
//                                     alt="more action icon"
//                                   />
//                                 </span>
//                               </div>
//                             </Col>
//                             <Col md={3} className="p-0">
//                               <div className="more-action-icon">
//                                 <img
//                                   src={MoreActionIcon}
//                                   alt="info circle icon"
//                                 />
//                               </div>
//                             </Col>
//                           </Row>
//                         </div>
//                         <SemiCircleProgressBar
//                           percentage={33}
//                           showPercentValue
//                           diameter={115}
//                           stroke={"#00D26E"}
//                           strokeWidth={8}
//                         />
//                         <div>
//                           <p className="widget-p-sub-text">$200/$5</p>
//                         </div>
//                         <div className="widget-status-container up">24.5%</div>
//                       </div>
//                     </Col>
//                     <Col md={6}>
//                       <div className="widget-container height-220">
//                         <div className="widget-header mb-4">
//                           <Row>
//                             <Col md={9} className="widget-select-container">
//                               <div className="input-group filter">
//                                 <select
//                                   className="form-select widget-select"
//                                   id="inputGroupSelect02"
//                                   defaultValue={"DEFAULT"}
//                                 >
//                                   <option value="DEFAULT">CR</option>
//                                   {/* <option value="1">One</option>
//                                   <option value="2">Two</option>
//                                   <option value="3">Three</option> */}
//                                 </select>
//                                 <span>
//                                   <img
//                                     src={InfoCircleIcon}
//                                     alt="more action icon"
//                                   />
//                                 </span>
//                               </div>
//                             </Col>
//                             <Col md={3} className="p-0">
//                               <div className="more-action-icon">
//                                 <img
//                                   src={MoreActionIcon}
//                                   alt="info circle icon"
//                                 />
//                               </div>
//                             </Col>
//                           </Row>
//                         </div>
//                         {!this.state.loading ? (
//                           <div className="spinHeader">
//                             <Spinner animation="border" role="status">
//                               <span className="visually-hidden">
//                                 Loading...
//                               </span>
//                             </Spinner>
//                           </div>
//                         ) : (
//                           <>
//                             <SemiCircleProgressBar
//                               percentage={this.state.CRAvarageValue}
//                               showPercentValue
//                               diameter={115}
//                               stroke={"#ED787F"}
//                               strokeWidth={8}
//                             />
//                             <div>
//                               <p className="widget-p-sub-text">
//                                 {this.state.CRData1 ? (
//                                   <>
//                                     ${this.state.CRData1}/{this.state.CRData2}
//                                   </>
//                                 ) : (
//                                   <>no data</>
//                                 )}
//                               </p>
//                               <div className="widget-status-container down">
//                                 {this.state.CRPercentage !== undefined &&
//                                 this.state.CRPercentage !== null ? (
//                                   !isNaN(
//                                     parseFloat(this.state.CRPercentage)
//                                   ) ? (
//                                     <>{parseFloat(this.state.CRPercentage)}%</>
//                                   ) : (
//                                     <p className="noData">no data</p>
//                                   )
//                                 ) : (
//                                   <p className="noData">no data</p>
//                                 )}
//                               </div>
//                             </div>
//                           </>
//                         )}
//                       </div>
//                     </Col>
//                   </Row>
//                 </Col>
//                 <Col md={6}>
//                   <div className="widget-container height-220">
//                     <div className="widget-header">
//                       <Row>
//                         <Col md={9} className="widget-select-container">
//                           <div className="input-group filter">
//                             <select
//                               className="form-select widget-select"
//                               id="inputGroupSelect02"
//                               defaultValue={"DEFAULT"}
//                             >
//                               <option value="DEFAULT">
//                                 Royalties [Distribution]
//                               </option>
//                               {/* <option value="1">One</option>
//                               <option value="2">Two</option>
//                               <option value="3">Three</option> */}
//                             </select>
//                             <span>
//                               <img
//                                 src={InfoCircleIcon}
//                                 alt="more action icon"
//                               />
//                             </span>
//                           </div>
//                         </Col>
//                         <Col md={3} className="p-0">
//                           <div className="more-action-icon">
//                             <img src={MoreActionIcon} alt="info circle icon" />
//                           </div>
//                         </Col>
//                       </Row>
//                     </div>
//                     <RoyaltiesDistributionChart />
//                     {/* < RedChart /> */}
//                     <div className="widget-footer">
//                       <Row>
//                         <Col md={7}>
//                           <div className="widget-footer-title">
//                             {/* ${this.state.royaltiesDistributionValue} */}
//                             {this.state.royaltiesDistributionValue ? (
//                               <>{this.state.royaltiesDistributionValue}%</>
//                             ) : (
//                               <p className="noData">no data</p>
//                             )}
//                           </div>
//                         </Col>
//                         <Col md={5} className="align-end">
//                           <div className="widget-status-container up">
//                             {this.state.royaltiesDistributionPercentage}%
//                           </div>
//                         </Col>
//                       </Row>
//                     </div>
//                   </div>
//                 </Col>
//               </Row>
//             </Col>
//             <Col md={6}>
//               <Row>
//                 <Col md={6}>
//                   <div className="widget-container height-220">
//                     <div className="widget-header">
//                       <Row>
//                         <Col md={9} className="widget-select-container">
//                           <div className="input-group filter">
//                             <select
//                               className="form-select widget-select"
//                               id="inputGroupSelect02"
//                               defaultValue={"DEFAULT"}
//                             >
//                               <option value="DEFAULT">Spend [Total]</option>
//                               {/* <option value="1">One</option>
//                               <option value="2">Two</option>
//                               <option value="3">Three</option> */}
//                             </select>
//                             <span>
//                               <img
//                                 src={InfoCircleIcon}
//                                 alt="more action icon"
//                               />
//                             </span>
//                           </div>
//                         </Col>
//                         <Col md={3} className="p-0">
//                           <div className="more-action-icon">
//                             <img src={MoreActionIcon} alt="info circle icon" />
//                           </div>
//                         </Col>
//                       </Row>
//                     </div>
//                     {/* <ChartsRed chart={this.state.categories} /> */}
//                     {/* {this.state.SpenLoading  ? <>loading</>: */}
//                     <SpendChart data={this.state.spendChartData} />
//                     {/* } */}

//                     <div className="widget-footer">
//                       <Row>
//                         <Col md={7}>
//                           <div className="widget-footer-title">
//                             {this.state.salesAvarageValue !== undefined &&
//                             this.state.salesAvarageValue !== null ? (
//                               !isNaN(
//                                 parseFloat(this.state.salesAvarageValue)
//                               ) ? (
//                                 // Parse the string representation to a floating-point number
//                                 `${parseFloat(this.state.salesAvarageValue)}%`
//                               ) : (
//                                 <p className="noData">no data</p>
//                               )
//                             ) : (
//                               <p className="noData">no data</p>
//                             )}
//                           </div>
//                         </Col>
//                         <Col md={5} className="align-end">
//                           <div className="widget-status-container down">
//                             {this.state.salesPercentage}%
//                           </div>
//                         </Col>
//                       </Row>
//                     </div>
//                   </div>
//                 </Col>
//                 <Col md={6}>
//                   <div className="widget-container height-220">
//                     <div className="widget-header">
//                       <Row>
//                         <Col md={9} className="widget-select-container">
//                           <div className="input-group filter">
//                             <select
//                               className="form-select widget-select"
//                               id="inputGroupSelect02"
//                               defaultValue={"DEFAULT"}
//                             >
//                               <option value="DEFAULT">
//                                 Royalties [Projected]
//                               </option>
//                               {/* <option value="1">One</option>
//                               <option value="2">Two</option>
//                               <option value="3">Three</option> */}
//                             </select>
//                             <span>
//                               <img
//                                 src={InfoCircleIcon}
//                                 alt="more action icon"
//                               />
//                             </span>
//                           </div>
//                         </Col>
//                         <Col md={3} className="p-0">
//                           <div className="more-action-icon">
//                             <img src={MoreActionIcon} alt="info circle icon" />
//                           </div>
//                         </Col>
//                       </Row>
//                     </div>
//                     <RoyaltiesProjected />
//                     <div className="widget-footer">
//                       <Row>
//                         <Col md={7}>
//                           <div className="widget-footer-title">
//                             {this.state.royaltiesProjectedValue ? (
//                               <>{this.state.royaltiesProjectedValue}%</>
//                             ) : (
//                               <p className="noData">no data</p>
//                             )}
//                           </div>
//                         </Col>
//                         <Col md={5} className="align-end">
//                           <div className="widget-status-container up">
//                             {this.state.royaltiesProjectedPercentage != null ? (
//                               <>{this.state.royaltiesProjectedPercentage}%</>
//                             ) : (
//                               <>0%</>
//                             )}
//                           </div>
//                         </Col>
//                       </Row>
//                     </div>
//                   </div>
//                 </Col>
//               </Row>
//             </Col>
//           </Row>
//           <Row>
//             <Col md={3}>
//               <div className="widget-container height-110">
//                 <div className="widget-header">
//                   <Row>
//                     <Col md={9} className="widget-select-container">
//                       <div className="input-group filter">
//                         <select
//                           className="form-select widget-select"
//                           id="inputGroupSelect02"
//                           defaultValue={"DEFAULT"}
//                         >
//                           <option value="DEFAULT">Budget</option>
//                           {/* <option value="1">One</option>
//                           <option value="2">Two</option>
//                           <option value="3">Three</option> */}
//                         </select>
//                         <span>
//                           <img src={InfoCircleIcon} alt="more action icon" />
//                         </span>
//                       </div>
//                     </Col>
//                     <Col md={3} className="p-0">
//                       <div className="more-action-icon">
//                         <img src={MoreActionIcon} alt="info circle icon" />
//                       </div>
//                     </Col>
//                   </Row>
//                 </div>
//                 <div className="widget-footer">
//                   <Row>
//                     <Col md={7}>
//                       <div className="widget-footer-title">
//                         {this.state.budgetValue !== undefined &&
//                         this.state.budgetValue !== null ? (
//                           !isNaN(parseFloat(this.state.budgetValue)) ? (
//                             `$${parseFloat(this.state.budgetValue)}`
//                           ) : (
//                             <p className="noData">no data</p>
//                           )
//                         ) : (
//                           <p className="noData">no data</p>
//                         )}
//                       </div>
//                     </Col>
//                     <Col md={5} className="align-end">
//                       <div className="widget-status-container down">
//                         {this.state.budgetAvgValue}%
//                       </div>
//                     </Col>
//                   </Row>
//                 </div>
//               </div>
//             </Col>
//             <Col md={3}>
//               <div className="widget-container height-110">
//                 <div className="widget-header">
//                   <Row>
//                     <Col md={9} className="widget-select-container">
//                       <div className="input-group filter">
//                         <select
//                           className="form-select widget-select"
//                           id="inputGroupSelect02"
//                           defaultValue={"DEFAULT"}
//                         >
//                           <option value="DEFAULT">CPC</option>
//                           {/* <option value="1">One</option>
//                           <option value="2">Two</option>
//                           <option value="3">Three</option> */}
//                         </select>
//                         <span>
//                           <img src={InfoCircleIcon} alt="more action icon" />
//                         </span>
//                       </div>
//                     </Col>
//                     <Col md={3} className="p-0">
//                       <div className="more-action-icon">
//                         <img src={MoreActionIcon} alt="info circle icon" />
//                       </div>
//                     </Col>
//                   </Row>
//                 </div>
//                 <div className="widget-footer">
//                   <Row>
//                     <Col md={7}>
//                       <div className="widget-footer-title">
//                         {this.state.CPCValue !== undefined &&
//                         this.state.CPCValue !== null ? (
//                           !isNaN(parseFloat(this.state.CPCValue)) ? (
//                             `${parseFloat(this.state.CPCValue)}%`
//                           ) : (
//                             <p className="noData">no data</p>
//                           )
//                         ) : (
//                           <p className="noData">no data</p>
//                         )}
//                       </div>
//                     </Col>
//                     <Col md={5} className="align-end">
//                       <div className="widget-status-container up">
//                         {this.state.CPCAvgValue}%
//                       </div>
//                     </Col>
//                   </Row>
//                 </div>
//               </div>
//             </Col>
//             <Col md={3}>
//               <div className="widget-container height-110">
//                 <div className="widget-header">
//                   <Row>
//                     <Col md={9} className="widget-select-container">
//                       <div className="input-group filter">
//                         <select
//                           className="form-select widget-select"
//                           id="inputGroupSelect02"
//                           defaultValue={"DEFAULT"}
//                         >
//                           <option value="DEFAULT">Sales [Ad]</option>
//                           {/* <option value="1">One</option>
//                           <option value="2">Two</option>
//                           <option value="3">Three</option> */}
//                         </select>
//                         <span>
//                           <img src={InfoCircleIcon} alt="more action icon" />
//                         </span>
//                       </div>
//                     </Col>
//                     <Col md={3} className="p-0">
//                       <div className="more-action-icon">
//                         <img src={MoreActionIcon} alt="info circle icon" />
//                       </div>
//                     </Col>
//                   </Row>
//                 </div>
//                 <div className="widget-footer">
//                   <Row>
//                     <Col md={7}>
//                       <div className="widget-footer-title">
//                         {this.state.salesValue !== undefined &&
//                         this.state.salesValue !== null ? (
//                           !isNaN(Number(this.state.salesValue)) ? (
//                             `$${Number(this.state.salesValue).toFixed(0)}`
//                           ) : (
//                             <p className="noData">no data</p>
//                           )
//                         ) : (
//                           <p className="noData">no data</p>
//                         )}
//                       </div>
//                     </Col>
//                     <Col md={5} className="align-end">
//                       <div className="widget-status-container up">
//                         {this.state.salesAvgValue}%
//                       </div>
//                     </Col>
//                   </Row>
//                 </div>
//               </div>
//             </Col>
//             <Col md={3}>
//               <div className="widget-container height-110">
//                 <div className="widget-header">
//                   <Row>
//                     <Col md={9} className="widget-select-container">
//                       <div className="input-group filter">
//                         <select
//                           className="form-select widget-select"
//                           id="inputGroupSelect02"
//                           defaultValue={"DEFAULT"}
//                         >
//                           <option value="DEFAULT">Orders [Ad]</option>
//                           {/* <option value="1">One</option>
//                           <option value="2">Two</option>
//                           <option value="3">Three</option> */}
//                         </select>
//                         <span>
//                           <img src={InfoCircleIcon} alt="more action icon" />
//                         </span>
//                       </div>
//                     </Col>
//                     <Col md={3} className="p-0">
//                       <div className="more-action-icon">
//                         <img src={MoreActionIcon} alt="info circle icon" />
//                       </div>
//                     </Col>
//                   </Row>
//                 </div>
//                 <div className="widget-footer">
//                   <Row>
//                     <Col md={7}>
//                       <div className="widget-footer-title">
//                         {/* {this.state.orderValue} */}
//                         {this.state.orderValue != null ? (
//                           <>{this.state.orderValue}</>
//                         ) : (
//                           <p className="noData">no data</p>
//                         )}
//                       </div>
//                     </Col>
//                     <Col md={5} className="align-end">
//                       <div className="widget-status-container up">
//                         {this.state.orderAvgValue}%
//                       </div>
//                     </Col>
//                   </Row>
//                 </div>
//               </div>
//             </Col>
//           </Row>
//           <Row>
//             <Col md={6}>
//               <div className="widget-container height-350px">
//                 <div className="widget-header">
//                   <Row>
//                     <Col md={5} className="widget-select-container dot-con">
//                       <span className="dot bg-black"></span>
//                       <div className="input-group filter">
//                         <select
//                           className="form-select widget-select"
//                           id="inputGroupSelect02"
//                           defaultValue={"DEFAULT"}
//                         >
//                           <option value="DEFAULT">Select [Type]</option>
//                           {/* <option value="1">One</option>
//                           <option value="2">Two</option>
//                           <option value="3">Three</option> */}
//                         </select>
//                         <span>
//                           <img src={InfoCircleIcon} alt="more action icon" />
//                         </span>
//                       </div>
//                     </Col>
//                     <Col md={7} className="p-0">
//                       <Row>
//                         <Col md={10}>
//                           <div className="widget-select-container pull-right dot-con">
//                             <span className="dot bg-green"></span>
//                             <div className="input-group filter pull-right">
//                               <select
//                                 className="form-select widget-select"
//                                 id="inputGroupSelect02"
//                                 defaultValue={"DEFAULT"}
//                               >
//                                 <option defaultValue={"DEFAULT"}>
//                                   Select [Type]
//                                 </option>
//                                 {/* <option value="1">One</option>
//                                 <option value="2">Two</option>
//                                 <option value="3">Three</option> */}
//                               </select>
//                               <span>
//                                 <img
//                                   src={InfoCircleIcon}
//                                   alt="more action icon"
//                                 />
//                               </span>
//                             </div>
//                           </div>
//                         </Col>
//                         <Col md={2}>
//                           <div className="more-action-icon">
//                             <img src={MoreActionIcon} alt="info circle icon" />
//                           </div>
//                         </Col>
//                       </Row>
//                     </Col>
//                   </Row>
//                 </div>
//                 <Areachart />
//               </div>
//             </Col>
//             <Col md={6}>
//               <div className="widget-container height-350px">
//                 <div className="widget-header">
//                   <Row>
//                     <Col md={5} className="widget-select-container dot-con">
//                       <span className="dot bg-black"></span>

//                       <div className="input-group filter">
//                         <select
//                           className="form-select widget-select"
//                           id="inputGroupSelect02"
//                           defaultValue={"DEFAULT"}
//                         >
//                           <option value="DEFAULT">Select [Type]</option>
//                           {/* <option value="1">One</option>
//                           <option value="2">Two</option>
//                           <option value="3">Three</option> */}
//                         </select>
//                         <span>
//                           <img src={InfoCircleIcon} alt="more action icon" />
//                         </span>
//                       </div>
//                     </Col>
//                     <Col md={7} className="p-0">
//                       <Row>
//                         <Col md={10}>
//                           <div className="widget-select-container pull-right dot-con">
//                             <span className="dot bg-green"></span>
//                             <div className="input-group filter">
//                               <select
//                                 className="form-select widget-select"
//                                 id="inputGroupSelect02"
//                                 defaultValue={"DEFAULT"}
//                               >
//                                 <option value="DEFAULT">Select [Type]</option>
//                                 {/* <option value="1">One</option>
//                                 <option value="2">Two</option>
//                                 <option value="3">Three</option> */}
//                               </select>
//                               <span>
//                                 <img
//                                   src={InfoCircleIcon}
//                                   alt="more action icon"
//                                 />
//                               </span>
//                             </div>
//                           </div>
//                         </Col>
//                         <Col md={2}>
//                           <div className="more-action-icon">
//                             <img src={MoreActionIcon} alt="info circle icon" />
//                           </div>
//                         </Col>
//                       </Row>
//                     </Col>
//                   </Row>
//                 </div>
//                 <Stackedbar />
//               </div>
//             </Col>
//           </Row>
//           <Row>
//             <Col>
//               <div className="top-seller-title">
//                 Top Bestseller Book
//                 <div className="dropdown">
//                   <select className="dropdown-content" defaultValue={"DEFAULT"}>
//                     <option value="DEFAULT">Royalties [TypeNet]</option>
//                     {/* <option value="1">One</option>
//                     <option value="2">Two</option>
//                     <option value="3">Three</option> */}
//                   </select>
//                   <div className="info icon">
//                     <span>
//                       <img src={InfoCircleIcon} alt="more action icon" />
//                     </span>
//                   </div>
//                 </div>
//               </div>
//             </Col>
//           </Row>
//           <BestSeller />
//           <Row>
//             <Col className="tabsContainer">
//               <Tabs
//                 defaultActiveKey="books"
//                 id="uncontrolled-tab-example"
//                 className="mb-3"
//               >
//                 <Tab eventKey="books" title="BOOKS">
//                   <BooksTable
//                     filterData={this.state.globalFilterFromData}
//                     searchKey={this.state.searchKeyFilter}
//                     filterDateRange={this.state.globalFilterDateRange}
//                   />
//                 </Tab>
//                 <Tab eventKey="authors" title="AUTHORS">
//                   <AuthorsTable
//                     filterData={this.state.globalFilterFromData}
//                     searchKey={this.state.searchKeyFilter}
//                     filterDateRange={this.state.globalFilterDateRange}
//                   />
//                 </Tab>
//                 <Tab eventKey="marketplace" title="MARKETPLACE">
//                   <MarketplaceTable
//                     filterData={this.state.globalFilterFromData}
//                     searchKey={this.state.searchKeyFilter}
//                     filterDateRange={this.state.globalFilterDateRange}
//                   />
//                 </Tab>
//               </Tabs>
//             </Col>
//           </Row>
//         </div>
//       </DashboardLayout>
//     );
//   }
// }
import React, { Component } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Col, Row } from "react-bootstrap";
import { DateRangePicker } from "rsuite";
import isAfter from "date-fns/isAfter";
import Moment from "moment";
interface ProfileOption {
  value: string; // Adjust the type according to your data
  label: string;
}
interface ResultProps {
  searchResult: any;
}

class index extends Component<ResultProps> {
  filterData: any = [];
  current = new Date();
  startDate: any = new Date().setDate(this.current.getDate() - 30);
  endDate = new Date();

  state = {
    globalFilterFromData: [],
    searchKeyFilter: "",
    globalFilterDateRange: [this.startDate, this.endDate],
    metaData: [],
    lastUpdatedDate: localStorage.getItem("lastUpdatedDate") || null,
    profileOptions:[] as ProfileOption[],
    selectedAccount:  "",
  };

  constructor(props: ResultProps) {
    super(props);
    this.onChangeDateRangeSubmitGlobal =
      this.onChangeDateRangeSubmitGlobal.bind(this);
      this.handleAccountChange = this.handleAccountChange.bind(this);
  }
  componentDidMount() {
  
    console.log(this.props.searchResult);
    
    //this.getProfile();
  }
  getProfile = async () => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
 
    try {
      const response = await fetch("https://adsautomation.agilensmartservices.com/Users/Profile", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: AuthToken,
        },
      });

      const responseData = await response.json();

      const profiles = responseData.result.awsProfilesPreferences;
      const profileOptionss = profiles.map((profile) => ({
        value: profile.profileId, 
        label: profile.profileName, 
      }));


        const firstProfileValue = profileOptionss[0].value;

      this.setState({
        profileOptions: profileOptionss,
        selectedAccount:firstProfileValue,
      }, () => {
       
      });

    } catch (error) {
      console.error("Error fetching profile:", error);
    }
  };
  handleAccountChange(event) {
    const selectedValue = event.target.value;
    this.setState({
      selectedAccount: selectedValue,
    });
 
  }
  
  onChangeDateRangeSubmitGlobal(e) {
    console.log(Moment(e[0]).format("YYYY-MM-DD"));
    console.log(e[1]);
    let dateRange = e;
    if (dateRange.length > 0) {
      this.setState({ globalFilterDateRange: dateRange });
    }
  }
  render() {
    return (
      <DashboardLayout>
        <div className="main-cont-header pt-5 mt-5 ">
          <Row className="page-header ">
            <Col>
              <div className="main-con-page-title-container">
                <div className="title">
                  <h3 className="page-title">Dashboard</h3>
                </div>

                {/* <div className="accounts me-2">
                  <select
                    className="form-select"
                    name="TypeSelect"
                    id="TypeSelect"
                  >
                    <option>Account-1</option>
                    <option>Account-2</option>
                    <option>Account-3</option>
                  </select>
                </div> */}
                <div className="filters">
                  <DateRangePicker
                    placeholder="Select Date Range"
                    onChange={this.onChangeDateRangeSubmitGlobal}
                    format="yyyy-MM-dd"
                    disabledDate={(date) => isAfter(date, new Date())}
                    // defaultValue={[this.startDate, new Date()]}
                  />
                </div>
                <div className="accounts ms-2">
                  <select
                    className="form-select"
                    name="accountSelect"
                    id="accountSelect"
                    style={{height:"38px"}}
                  >
                    <option>Sponsored Product</option>
                    <option>Sponsored Display</option>
                    <option>Sponsored Brand</option>
                  </select>
                </div>
               
              </div>
            </Col>

            {/* <Col className="text-end last-sync">
              <span>
              
                Last App Sync :
              </span>
              <span className="time-summery">
                {this.state.lastUpdatedDate || "Not available"}
              </span>
            </Col> */}
          </Row>
          <Row className="d-flex justify-content-center pt-5">
          
            <div className="top-box d-flex">
              <div
                className="box text-center"
                style={{ backgroundColor: "#FE9900" }}
              >
                <div className="pt-2">50000</div>
                <div>Impression</div>
              </div>
              <div
                className="box text-center"
                style={{ backgroundColor: "#FE9900" }}
              >
                <div className="pt-2">30000</div>
                <div>Clicks</div>
              </div>
              <div
                className="box text-center"
                style={{ backgroundColor: "#FE9900" }}
              >
                <div className="pt-2">10000</div>
                <div>Sales</div>
              </div>
              <div
                className="box text-center"
                style={{ backgroundColor: "#FE9900" }}
              >
                <div className="pt-2">$8000</div>
                <div>ROI</div>
              </div>
            </div>
          </Row>
    
          {/* <div className="profile2 ms-2">
                  <select
                    className="form-select"
                    name="TypeSelect"
                    id="TypeSelect"
                    value={this.state.selectedAccount}
                    onChange={this.handleAccountChange}
                  >
                    {this.state.profileOptions.map((profile) => (
                      <option key={profile.value} value={profile.value}>
                        {profile.label}
                      </option>
                    ))}
                  </select>
                </div> */}

         
        
        </div>
      </DashboardLayout>
    );
  }
}

export default index;
