import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { CircularProgress, Pagination, Stack } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import view from "../../assets/images/icons/view-report.png";
import view1 from "../../assets/images/icons/view-report-1.png";
import approved from "../../assets/images/icons/approve.png";
import approved1 from "../../assets/images/icons/approve-1.png";
import { Tooltip } from "antd";

let columns: GridColDef[] = [];
var targetId = "";
interface Report {
  dateRange: string;
  impressions: number;
  clicks: number;
  orders: number;
  acos: number;
}
interface ResultProps {
  searchResult: any;
}
const Recommendation: React.FunctionComponent<ResultProps> = (props) => {
  let currPage = 1;
  let dataLength = 50;
  const [isHovered2, setIsHovered2] = useState(null);
  const [hoveredRowId, setHoveredRowId] = useState(null);
  const [apiLoading, setApiLoading] = useState(false);
  const [approvePopup, setapprovePopup] = useState(false);
  const [viewDataPopup, setviewDataPopup] = useState(false);
  const [profileId, setProfileId] = useState("");
  const [intrevalSelectedValue, setintrevalSelectedValue] =
    useState("Last7Days");
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState([]);
  const [perPage, setPerPage] = useState(50);
  const [lastPage, setLastPage] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [totalRow, setTotalRow] = useState(0);
  const [reportLoading, setReportLoading] = useState(false);
  const [singleApprove, setSingleApprove] = useState({
    id: [],
    description: "",
  });
  const [reportsRow, setReportsRow] = useState<Report[]>([
    {
      dateRange: "2024-03-01_2024-03-08",
      impressions: 59,
      clicks: 0,
      orders: 0,
      acos: 0,
    },
    {
      dateRange: "2024-02-23_2024-03-01",
      impressions: 61,
      clicks: 0,
      orders: 0,
      acos: 0,
    },
  ]);

  useEffect(() => {
    setApiLoading(true);
    if(props.searchResult !== ""){
    Recommendation();}
  }, [searchText ,props.searchResult]);

  useEffect(() => {
    setProfileId(props.searchResult)
  }, [props.searchResult])

  const applyDataLength = (e) => {
    console.log("dataSize: ", e.target.value);
    dataLength = parseInt(e.target.value);
    Recommendation();
  };

  const Recommendation = async () => {
    setApiLoading(true);
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    let url =
      "https://adsautomation.agilensmartservices.com/Recommendation/All";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
      body: JSON.stringify({
        profileId: props.searchResult,
        pageNumber: currPage,
        pageSize: dataLength,
        globalFilters: {
          searchText: searchText,
          advancedFilters: null,
        },
      }),
    };

    try {
      const response = await fetch(url, requestOptions);
      const responceData = await response.json();
      if (responceData.success) {
        setApiLoading(false);
        let result = responceData.result;
        setRows(result.data);
        setTotalRow(result.filteredCount);
        setPerPage(result.perPage);
        setActivePage(result.currPage);
        setLastPage(result.lastPage);

        // if (columns.length < 1) {
        //   let headers = responceData.result.headers;

        //   for (let i = 0; headers.length > i; i++) {
        //     if (headers[i]["keyName"] === "campaignId") {
        //       columns.push({
        //         field: headers[i]["keyName"],
        //         headerName: headers[i]["displayName"],
        //         flex: 0.5,
        //         renderCell: (params) => {
        //           return (
        //             <>
        //               <div className="col-rulename">
        //                 <div className="col-title">{params.row.campaignId}</div>
        //               </div>
        //             </>
        //           );
        //         },
        //       });
        //     } else if (headers[i]["keyName"] === "campaignName") {
        //       columns.push({
        //         field: headers[i]["keyName"],
        //         headerName: headers[i]["displayName"],
        //         flex: 1,
        //         renderCell: (params) => {
        //           return (
        //             <>
        //               <div className="col-rulename">
        //                 <div className="col-title">
        //                   {params.row.campaignName}
        //                 </div>
        //               </div>
        //             </>
        //           );
        //         },
        //       });
        //     } else if (headers[i]["keyName"] === "campaignBudget") {
        //       columns.push({
        //         field: headers[i]["keyName"],
        //         headerName: headers[i]["displayName"],
        //         flex: 0.5,
        //         renderCell: (params) => {
        //           return (
        //             <>
        //               <div className="col-rulename">
        //                 <div className="col-title">
        //                   {params.row.campaignBudget}
        //                 </div>
        //               </div>
        //             </>
        //           );
        //         },
        //       });
        //     } else if (headers[i]["keyName"] === "status") {
        //       columns.push({
        //         field: headers[i]["keyName"],
        //         headerName: headers[i]["displayName"],
        //         flex: 0.5,
        //         renderCell: (params) => {
        //           return (
        //             <>
        //               <div className="col-rulename">
        //                 <div className="col-title">{params.row.status}</div>
        //               </div>
        //             </>
        //           );
        //         },
        //       });
        //     } else if (headers[i]["keyName"] === "rules") {
        //       columns.push({
        //         field: headers[i]["keyName"],
        //         headerName: headers[i]["displayName"],
        //         flex: 1,
        //         renderCell: (params) => {
        //           const ruleNames = params.value
        //             .map((rule) => rule.ruleName)
        //             .join(", ");
        //           return <span>{ruleNames}</span>;
        //         },
        //       });
        //     } else {
        //       columns.push({
        //         field: headers[i]["keyName"],
        //         headerName: headers[i]["displayName"],
        //         minWidth: 100,
        //       });
        //     }
        //   }
        //   columns.push({
        //     field: "rowAction",
        //     sortable: false,
        //     filterable: false,
        //     disableColumnMenu: true,
        //     headerName: "Action",
        //     minWidth: 200,
        //     renderCell: (params) => {
        //       return (
        //         <>
        //           <div className="d-flex">
        //             <Tooltip placement="top" title="Add Rules">
        //               <button
        //                 className="primary me-3"
        //                 onClick={() => {
        //                   rulesPopup(params, "Update");
        //                 }}
        //               >
        //                 +
        //               </button>
        //             </Tooltip>
        //             <Tooltip placement="top" title="Delete Rules">
        //               <button
        //                 className="primary me-3"
        //                 onClick={() => {
        //                   rulesPopup(params, "Delete");
        //                 }}
        //               >
        //                 -
        //               </button>
        //             </Tooltip>
        //           </div>
        //         </>
        //       );
        //     },
        //   });
        // }
      } else {
        setApiLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = (event, value: number) => {
    currPage = value;
    Recommendation();
  };

  const handleSelectChange = (event) => {
    console.log(event.target.value);
    setintrevalSelectedValue(event.target.value);
    setReportLoading(true)
    axios
      .get(
        `https://adsautomation.agilensmartservices.com/Recommendation/ComparisonData?TargetId=${targetId}&Daterange=${event.target.value}`
      )
      .then((res) => {
        setReportsRow(res.data.result.comparissionData);
        setReportLoading(false)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      setSearchText(event.target.value);
      event.preventDefault();
    }
  };

  const approve = (params) => {
    console.log(params);
    console.log(params.row.id);
    const id = params.row.id;
    setSingleApprove((prev: any) => ({
      ...prev,
      id: [...prev.id, id],
    }));
    setapprovePopup(true);
  };

  const viewData = (params) => {
    console.log(params.row.targetId);
    targetId = params.row.targetId;
    setviewDataPopup(true);
    setReportLoading(true)

    axios
      .get(
        `https://adsautomation.agilensmartservices.com/Recommendation/ComparisonData?TargetId=${params.row.targetId}&Daterange=${intrevalSelectedValue}`
      )
      .then((res) => {
        setReportsRow(res.data.result.comparissionData);
        setReportLoading(false)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const save = () => {
    console.log(singleApprove);
    setapprovePopup(false);

    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    let url =
      "https://adsautomation.agilensmartservices.com/Recommendation/Approve";

    axios
      .post(url, singleApprove, {
        headers: {
          "Content-Type": "application/json",
          Authorization: AuthToken,
        },
      })
      .then((response) => {
        if (response.data.success) {
          toast("Approved successfully");
          Recommendation()
        } else {
          toast("Approved failed");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const noteChange = (event) => {
    console.log(event.target.value);
    setSingleApprove((prev) => ({
      ...prev,
      description: event.target.value,
    }));
  };

  columns = [
    {
      field: "ruleName",
      headerName: "Rule Name",
      flex: 0.2,
      minWidth:130
    },
    {
      field: "campaignName",
      headerName: "Campaign Name",
      flex: 0.5,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <>
            <div className="col-rulename">
              <div className="col-title"  style={{ overflow: "hidden", whiteSpace: "normal" }}>
                {params.row.campaignName}
              </div>
            </div>
          </>
        );
      },
    },
    {
      field: "adGroupName",
      headerName: "AdGroup Name",
      minWidth:150
    },
    {
      field: "target",
      headerName: "Target",
      flex: 0.3,
      minWidth: 170,
    },
    {
      field: "action",
      headerName: "Action",
      minWidth:180,
      flex:0.3,
      renderCell: (params) => (
        <div>
          <div>Changed Value : {params.value.changedValue}</div>
          <div>Previous Value : {params.value.previousValue}</div>
          <div>Current Value : {params.value.currentValue}</div>
        </div>
      ),
    },
    {
      field: "time",
      headerName: "Time",
      minWidth:170
    },
    {
      field: "approvedOn",
      headerName: "Approved On",
      minWidth:130
    },
    {
      field: "rowAction",
      headerName: "",
      width: 90,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div>
          <Tooltip placement="top" title="Approve">
          <img
            className=" me-3"
            src={hoveredRowId === params.row.id || params.row.approvedOn !== null ? approved : approved1}
            height={25}
            width={25}
            onMouseEnter={() => setHoveredRowId(params.row.id)}
            onMouseLeave={() => setHoveredRowId(null)}
            alt=""
            onClick={() => approve(params)}
          />
    </Tooltip>
    <Tooltip placement="top" title="Compare">
          <img
            src={isHovered2 === params.row.id ? view : view1}
            height={20}
            onMouseEnter={() => setIsHovered2(params.row.id)}
            onMouseLeave={() => setIsHovered2(null)}
            width={20}
            alt=""
            onClick={() => {
              viewData(params);
            }}
          ></img>
          </Tooltip>
        </div>
      ),
    },
  ];

  const viewDataColumns = [
    { field: "dateRange", headerName: "", width: 200 },
    { field: "impressions", headerName: "Impression", width: 150 },
    { field: "clicks", headerName: "Clicks", width: 150 },
    { field: "orders", headerName: "Orders", width: 150 },
    { field: "acos", headerName: "ACOS", width: 100 },
  ];
  return (
    <DashboardLayout>
      <Row className="ms-2 me-3">
        <div className="action-row d-flex justify-content-between mt-5 ">
          <div className="back-arrow-container d-flex align-items-center  ">
            <span className="title">Recommendation</span>
          </div>
          <div className="d-flex">
            <form className="me-3">
              <div className="search-filter-container ">
                <i className="fa fa-search"></i>
                <input
                  type="text"
                  placeholder="Search"
                  id="globalSearch"
                  name="globalSearch"
                  onKeyDown={handleKeyDown}
                />
              </div>
            </form>
          </div>
          {approvePopup && (
            <div>
              <Modal
                show={approvePopup}
                onHide={() => setapprovePopup(false)}
                size="lg"
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title
                    id="example-modal-sizes-title-lg"
                    style={{ fontSize: "17px" }}
                  >
                    Approve
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row>
                    <div>
                      <Form.Label>Note </Form.Label>
                      <div >
                      <Form.Control
                        className="textarea"
                        as="textarea"
                        rows={4}
                        onChange={noteChange}
                      />
                      </div>
                    
                    </div>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    className="btn btn-primary add-rules-btn pt-2"
                    style={{ height: "43px !important" }}
                    onClick={save}
                  >
                    Save
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          )}
          {viewDataPopup && (
            <div>
              <Modal
                show={viewDataPopup}
                onHide={() => setviewDataPopup(false)}
                size="lg"
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title
                    id="example-modal-sizes-title-lg"
                    style={{ fontSize: "17px" }}
                  >
                    Reports
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row>
                    <Col md={6}>
                      <Form.Label>Interval</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        value={intrevalSelectedValue}
                        onChange={handleSelectChange}
                      >
                        <option value="Last7Days">Last 7 Days</option>
                        <option value="Last14Days">Last 14 Days</option>
                        <option value="Last30Days">Last 30 Days</option>
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <div className="data mt-3">
                      <DataGrid
                        rows={reportsRow.map((row, index) => ({
                          id: index,
                          ...row,
                        }))}
                        columns={viewDataColumns}
                        disableRowSelectionOnClick
                        loading={reportLoading}
                        style={{ height: "180px" }}
                        hideFooter
                      />
                    </div>
                  </Row>
                </Modal.Body>
              </Modal>
            </div>
          )}
        </div>
        <div style={{ height: "calc(80vh - 120px)", width:"100%" }}>
        <DataGrid
          className="mt-3"
          rows={rows}
          columns={columns}
          disableRowSelectionOnClick
          loading={apiLoading}
          hideFooter={true}
          rowHeight={40}
          
          rowSelection
        />
        <div className="custom-table-footer">
          <Row>
            <Col md={5}>
              <form className="table-footer-left">
                <span>Show </span>
                <label>
                  <select
                    className="form-select"
                    defaultValue={perPage}
                    onChange={(event) => applyDataLength(event)}
                  >
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="150">150</option>
                    <option value="200">200</option>
                  </select>
                </label>
                <span> of {totalRow} total entries</span>
              </form>
            </Col>
            <Col md={7}>
              <div className="table-footer-right">
                <Stack spacing={2}>
                  <Pagination
                    count={lastPage}
                    page={activePage}
                    variant="outlined"
                    shape="rounded"
                    onChange={handleChange}
                  />
                </Stack>
              </div>
            </Col>
          </Row>
        </div>
        </div>
      </Row>
    </DashboardLayout>
  );
};

export default Recommendation;
