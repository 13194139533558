/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../index.css";
import CreateRuleWizard from "../../../../components/Wizard/createRuleWizard";
import LogoIcon from "../../../../assets/brand/logo-icon.svg";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";

function AddNewRules(props) {
  const [metaData, setMetaData] = useState<any>([]);
  const [rulesMetaData, setRulesMetaData] = useState<any>({});
  const [name, setName] = useState<any>("");
  const [templateId, setTemplateId] = useState<any>("");
  const [marketPlaces, setMarketPlaces] = useState<any>([]);
  const [applyTo, setApplyTo] = useState<any>([]);
  const [matchTypes, setMatchTypes] = useState<any>([]);
  const [targetCriteriaDetails, setTargetCriteriaDetails] = useState<any>([]);
  const [reportDuration, setReportDuration] = useState<any>("");
  const navigate = useNavigate();
  const [actionCriteria, setActionCriteria] = useState<any>([]);
  const [actionUpToCriteria, setActionUpToCriteria] = useState<any>({});
  const [schedule, setSchedule] = useState<any>({
    runContinuously: true,
    dateRange: null,
  });
  const [addRulesObj, setAddRulesObj] = useState<any>({});

  const [getRuleStatus, setGetRuleStatus] = useState<any>(false);
  const [templateShow, setTemplateShow] = useState<any>(true);

  const [wizardData, setWizardData] = useState<any>({
    name: "",
    applyToWizard: [],
    ifWizard: [],
    forWizard: {},
    thenWizard: [],
    untilWizard: {},
    // timelineWizard: { runContinuously: true, dateRange: null },
  });
  const [isChecked, setChecked] = useState(false);

  const handleCheckboxChange = () => {
    setChecked(!isChecked);
  };
  useEffect(() => {
    console.log("kkkkkkk",props);
    
    const id = localStorage.getItem("templateId");
    setTemplateId(id);
    console.log(templateId);

    if (props.id !== "0" && templateId === null) {
      setGetRuleStatus(true);
      setTemplateShow(true)
      getRule();
    } else if (props.id !== "0" && templateId !== null) {
      setTemplateShow(false)
      templateCall();
    }
  }, [props.id, templateId]);

  const wizardCallback = (childData) => {
    console.log(childData);

    if (childData) {
      let newWizardData = wizardData;
      if (childData.type === "applyTo") {
        newWizardData["applyToWizard"] = childData.applyTo;
      }
      if (childData.type === "targetCriteria") {
        newWizardData["ifWizard"] = childData.targetCriteria;
      }
      if (childData.type === "reportDuration") {
        newWizardData["forWizard"] = childData.frequency;
      }

      if (childData.type === "actionCriteria") {
        newWizardData["thenWizard"] = childData.actionCriteria;
      }
      if (childData.type === "actionUpToCriteria") {
        newWizardData["untilWizard"] = childData.actionUpToCriteria;
      }

      setWizardData(newWizardData);
      console.log("hhhhh", newWizardData);
    }
  };

  const handleCallback = (childData) => {
    console.log("child", childData);

    if (childData.type === "applyTo") {
      setApplyTo(childData.applyTo);
    }
    if (childData.type === "targetCriteria") {
      setTargetCriteriaDetails(childData.targetCriteria);
    }
    if (childData.type === "reportDuration") {
      setReportDuration(childData.frequency);
    }
    if (childData.type === "actionCriteria") {
      setActionCriteria(childData.actionCriteria);
    }
    if (childData.type === "actionUpToCriteria") {
      setActionUpToCriteria(childData.actionUpToCriteria);
    }

    if (childData.type === "submit") {
      if (props.id !== "0" && templateId === null ) {
        submitUpdateRule();
      } else if(props.id !== "0" && templateId !== null)
      {
        submitAddRule();
      }
      else{
        submitAddRule();
      }
    }
  
  };

  const submitAddRule = async () => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    let url = "https://adsautomation.agilensmartservices.com/Rule";
    const action = {
      data: actionCriteria,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
      body: JSON.stringify({
        profileId:props.selectedAccount,
        name: name,
        template: isChecked,
        applyTo: applyTo,
        targetCriteria: targetCriteriaDetails,
        frequency: reportDuration,
        actionCriteria: action,
        automationCriteria: actionUpToCriteria,
      }),
    };

    try {
      const response = await fetch(url, requestOptions);
      const responceData = await response.json();
      if (responceData.success) {
        let result = responceData.result;
        toast("Successfully Rule created");
        navigate("/ads/automation-rules");
      } else {
        toast("Unable to create Rule");
      }
      // setRedirect("/onboarding");
    } catch (error) {
      console.error("Error fetching data:", error);
      toast("Error On Creating Rule");
    }
  };
  const templateCall = async () => {
    if(templateId !==""){
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    let url =
      "https://adsautomation.agilensmartservices.com/Rule/Template/" +
      templateId;
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
    };

    try {
     
      const response = await fetch(url, requestOptions);
      const responceData = await response.json();
      if (responceData.success) {
        let result = responceData.result;
        console.error("Get Rules: ", result);
        
        let objWizard = {
          id: result.id,
          template: result.template,
          name: result.ruleName,
          applyToWizard: result.applyTo,
          ifWizard: result.targetCriteria,
          forWizard: result.frequency,
          thenWizard: result.actionCriteria.data,
          untilWizard: result.automationCriteria,
        };
        console.log(objWizard);

        setName(result.ruleName);
        setApplyTo(result.applyTo);
        setTargetCriteriaDetails(result.targetCriteria);
        setReportDuration(result.frequency);
        setActionCriteria(result.actionCriteria.data);
        setActionUpToCriteria(result.automationCriteria);
        setWizardData(objWizard);
        console.log(wizardData);
        // props.parentCallback("AddNewRules");
      } else {
      }
    
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  };

  const getRule = async () => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    let url = "https://adsautomation.agilensmartservices.com/Rule/" + props.id;
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
    };

    try {
      const response = await fetch(url, requestOptions);
      const responceData = await response.json();
      if (responceData.success) {
        let result = responceData.result;
        console.error("Get Rules: ", result);
        let objWizard = {
          id: result.id,
          template: result.template,
          name: result.ruleName,
          applyToWizard: result.applyTo,
          ifWizard: result.targetCriteria,
          forWizard: result.frequency,
          thenWizard: result.actionCriteria.data,
          untilWizard: result.automationCriteria,
        };
        console.log(objWizard);

        setName(result.ruleName);
        setMarketPlaces(result.marketPlaces);
        setApplyTo(result.applyTo);
        setMatchTypes(result.matchTypes);
        setTargetCriteriaDetails(result.targetCriteria);
        setReportDuration(result.frequency);
        setActionCriteria(result.actionCriteria.data);
        setActionUpToCriteria(result.automationCriteria);
        setChecked(result.template);
        setWizardData(objWizard);
        setGetRuleStatus(false);
        console.log(wizardData);
      } else {
        setGetRuleStatus(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast("Error On loading Rule");
      setGetRuleStatus(false);
    }
  };
  const submitUpdateRule = async () => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    let url = "https://adsautomation.agilensmartservices.com/Rule";
    const action = {
      data: actionCriteria,
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
      body: JSON.stringify({
        profileId:props.selectedAccount,
        id: props.id,
        name: name,
        template: isChecked,
        applyTo: applyTo,
        targetCriteria: targetCriteriaDetails,
        frequency: reportDuration,
        actionCriteria: action,
        automationCriteria: actionUpToCriteria,
      }),
    };

    try {
      const response = await fetch(url, requestOptions);
      const responceData = await response.json();
      if (responceData.success) {
        let result = responceData.result;
        console.error(result);
        toast("Successfully Rule updated");
        navigate("/ads/automation-rules");
      } else {
        toast("Unable to create Rule");
      }
      // setRedirect("/onboarding");
    } catch (error) {
      console.error("Error fetching data:", error);
      toast("Error On Creating Rule");
    }
  };

  let handleChange = (e) => {
    let newFormValues = addRulesObj;
    newFormValues["name"] = e.target.value;
    setName(e.target.value);
    let newWizardDataName = wizardData;
    newWizardDataName["name"] = e.target.value;
    setWizardData(newWizardDataName);
    console.log("Wizard Data: ", newWizardDataName);
  };

  useEffect(() => {
    if (rulesMetaData) {
      if (!rulesMetaData.applyToDetails) {
        getRulesMetaData();
      
      }
    }
    if (metaData) {
      if (metaData.length < 1) {
        getMetaData();
      }
    }
  }, [metaData, rulesMetaData]);

  const getMetaData = async () => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    let url = "https://adsautomation.agilensmartservices.com/MasterData/meta";
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
    });

    try {
      const responceData = await response.json();
      setMetaData({ metaData: responceData.result });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getRulesMetaData = async () => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    let url =
      "https://adsautomation.agilensmartservices.com/MasterData/Rules_Creation";
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
    });

    try {
      const responceData = await response.json();
      setRulesMetaData(responceData.result.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent form submission
    // Additional logic here if needed
  };


  return (
    <>
      <div className="add-rule-container add-new-rule">
        <Row>
          <Col>
            <h4>Add New Rule</h4>
          </Col>
        </Row>
        {!getRuleStatus ? (
          <Row className="mt-3">
            <Col md={8} className="d-flex">
              <div className="mb-3 d-flex align-items-center me-5">
                <h5 className="me-3">Rule Name</h5>
                <form  onSubmit={handleSubmit}>
                  <div>
                    <input
                      className="form-control"
                      type="text"
                      style={{ width: "350px " }}
                      placeholder="Enter rule name "
                      id="globalSearch"
                      name="globalSearch"
                      onChange={(e) => handleChange(e)}
                      defaultValue={name}
                    />
                  </div>
                </form>
              </div>
              {templateShow  && (
                <div className="form-check mt-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <label className="form-check-label">Template</label>
                </div>
              )}
            </Col>
            <Row>
              <Col className="steperDesign">
                {rulesMetaData && (
                  <CreateRuleWizard
                    rulesMetaData={rulesMetaData}
                    metaData={metaData}
                    parentCallback={handleCallback}
                    wizardData={wizardData}
                    selectedAccount={props.selectedAccount}
                    wizardCallback={wizardCallback}
                  />
                )}
              </Col>
            </Row>
          </Row>
        ) : (
          <div className="loading-container">
            <div
              style={{
                position: "absolute",
                top: "30%",
                left: 0,
                width: "100%",
                height: "100%",
              }}
            >
              <CircularProgress
                className="loading"
                style={{ margin: "auto" }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AddNewRules;
