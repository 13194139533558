/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Row, Col, Button, Pagination, Modal, Form } from "react-bootstrap";

import LogoIcon from "../../../../../assets/brand/logo-icon.svg";
import DuplicateIcon from "../../../../../assets/images/icons/duplicate-icon.svg";
import RemoveIcon from "../../../../../assets/images/icons/trash-icon.svg";

import Select, { components } from "react-select";
import { CircularProgress } from "@mui/material";
import axios from "axios";

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input type="checkbox" defaultChecked={isSelected} />
      {children}
    </components.Option>
  );
};
function AddRulesApplyto(props) {
  const [ruleDataLoading, setRuleDataLoading] = useState(false);
  const [metaDataLoading, setMetaDataLoading] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [selectedInput, setSelectedInput] = useState("");
  const [selectedCampignShow, setSelectedCampaignShow] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState();
  const [newSelectedOptions, setNewSelectedOptions] = useState([]);
  const [campaignData, setCampaignData] = useState([]);

  const [metaData, setMetaData] = useState([]);
  const [rulesMetaData, setRulesMetaData] = useState({});
  const [actionCriteriaDetails, setActionCriteriaDetails] = useState([]);
  const [actionUpToCriteriaDetails, setActionUpToCriteriaDetails] = useState(
    []
  );
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [show, setShow] = useState(false);
  const [applyTo, setApplyTo] = useState([]);
  const [marketPlaces, setMarketPlaces] = useState([]);
  const [marketPlacesDropdown, setMarketPlacesDropdown] = useState([]);
  const [matchTypes, setMatchTypes] = useState([]);
  const [matchTypesSelected, setMatchTypesSelected] = useState({
    autoCampaign: [],
    manualCampaign: { keywordMatch: [], productMatch: [] },
  });
  const [reportDuriationDetails, setReportDuriationDetails] = useState([]);
  const [targetCriteriaDetails, setTargetCriteriaDetails] = useState([]);
  const [pageData, setPageData] = useState([]);

  const [formValues, setFormValues] = useState([
    { conditionFilter: "", conditionName: "", conditionValue: "" },
  ]);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [selectedCampaigns2, setSelectedCampaigns2] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [importFile, setImportFile] = useState(null);
  const [ruleId, setRuleId] = useState("");

  const handleChangeInput = (e) => {
    if(e.key ==="Enter"){
      setSelectedInput(e.target.value);
    }
  };



  const handleCheckboxChange = (clickedCampaign) => {
    setSelectedCampaigns(clickedCampaign);
    if (clickedCampaign === "selectAll") {
      // If "Select All" checkbox is clicked
      console.log("selectAll,");
      console.log("selectAllIf",selectAllChecked);
      setSelectAllChecked(!selectAllChecked);
      if(selectAllChecked ===true){
        setSelectedCampaigns([]);
        wizardDataUpdate();
      }
      else{
        setSelectedCampaigns( campaignData);
      }
      
     
    } 
    else{
    const isSelected = selectedCampaigns.some(
      (selectedCampaign) =>
        selectedCampaign.campaignName === clickedCampaign.campaignName
    );
  

    if (isSelected) {
      console.log("l",isSelected);
      const updatedSelectedCampaigns = selectedCampaigns.filter(
        (selectedCampaign) =>
          selectedCampaign.campaignName !== clickedCampaign.campaignName
      );
      console.log("ll",updatedSelectedCampaigns);
      setSelectedCampaigns(updatedSelectedCampaigns);
      wizardDataUpdate();
    } else {
      console.log("k",isSelected);
      const updatedSelectedCampaigns = [...selectedCampaigns, clickedCampaign];
      setSelectedCampaigns(updatedSelectedCampaigns);
      wizardDataUpdate();
    }
  }
 
   
  
  };

  const handleDelete = (campaign) => {
    setSelectedCampaigns2((prevSelected) =>
      prevSelected.filter((c) => c.campaignId !== campaign.campaignId)
    );
  };

  useEffect(() => {
    console.log(props);
    if (props.rulesMetaData) {
      let applyToDetails = props.rulesMetaData.applyToDetails;
      if (applyToDetails !== undefined) {
        if(props.wizardData.id !== 0){
          setRuleId(props.wizardData.id)

        }
        setRulesMetaData(props.rulesMetaData);
        setActionCriteriaDetails(props.rulesMetaData.actionCriteriaDetails);
        setActionUpToCriteriaDetails(
          props.rulesMetaData.actionUpToCriteriaDetails
        );
        setApplyTo(applyToDetails.applyTo);
        setMarketPlaces(applyToDetails.marketPlaces);
        let marketplaceList = applyToDetails.marketPlaces;
        let newMarketplaceList = [];
        if (marketplaceList.length > 0) {
          for (let i = 0; i < marketplaceList.length; i++) {
            let obj = {
              value: marketplaceList[i].country,
              label: marketplaceList[i].country,
            };
            newMarketplaceList.push(obj);
          }
        }
        setReportDuriationDetails(props.rulesMetaData.reportDurationDetails);
        setTargetCriteriaDetails(props.rulesMetaData.targetCriteriaDetails);
        setRuleDataLoading(false);
      }
    }
    setMetaData(props.metaData);
  }, [props.rulesMetaData, rulesMetaData, props.metaData, metaData]);

  useEffect(() => {
    if (props.metaData && props.rulesMetaData.applyToDetails) {
      if (props.metaData.text) {
        buildApplyToDropdownData(
          props.rulesMetaData.applyToDetails.applyTo,
          props.metaData
        );
        setMetaDataLoading(false);
      }
    }
  }, [props.rulesMetaData, props.metaData]);

  useEffect(() => {
    console.log(props.selectedAccount);
  if(ruleId !== "" ){
      setLoading(true);
      let userToken = localStorage.getItem("userToken");
      let AuthToken = "Bearer " + userToken;
      const formData = new FormData();
  
      if (selectedFile !== null) {
        formData.append("file", selectedFile);
      } else {
        formData.append("file", "");
      }
      if (ruleId !== undefined) {
        formData.append("RuleId", ruleId);
      } else {
        formData.append("RuleId", "");
      }
  
      formData.append("SearchText", selectedInput);
      formData.append("ProfileId", String(props.selectedAccount));
  
      let url = "https://adsautomation.agilensmartservices.com/Rule/CampaignName";
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: AuthToken,
        },
        body: formData,
      };
  
       fetch(url, requestOptions)
        .then(response => response.json())
        .then(responceData => {
          if (responceData.success) {
            let result = responceData.result;
            console.log(props);
            if (selectedFile !== null) {
              const selectedCampaigns = result.filter(campaign => campaign.isSelected);
              setSelectedCampaigns(selectedCampaigns);
            }
  
            setCampaignData(result);
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch(error => {
          console.error("Error fetching data:", error);
        });
      }

  }, [selectedInput, ruleId, selectedFile]);
 

  useEffect(() => {
  
    console.log("Applyto page Data: ", props.wizardData.applyToWizard?.campaigns);

    if (
      props.wizardData.applyToWizard &&
      Array.isArray(props.wizardData.applyToWizard)
    ) {
      const selected = props.wizardData.applyToWizard.map(
        (campaign) => ({
          campaignId: campaign.campaignId,
          campaignName: campaign.campaignName,
        })
      );
      setSelectedCampaigns(selected);
      
    
    }
    else if(
      props.wizardData.applyToWizard?.campaigns
    ){
      const selected = props.wizardData.applyToWizard?.campaigns.map(
        (campaign) => ({
          campaignId: campaign.campaignId,
          campaignName: campaign.campaignName,
        })
      );
      setSelectedCampaigns(selected);
    }
  
  }, []);

  /* Apply to wizard */
  const handleSelect = (data) => {
    console.log("Selected market places", data);
    setSelectedOptions(data || []); // Update state immediately
    wizardDataUpdate("marketplace", data);
  };

  let matchTypesSelect = (matchType, e) => {
    let newMatchTypesSelected = matchTypesSelected;
    if (matchType === "autoCampaign") {
      if (e.target.checked) {
        newMatchTypesSelected["autoCampaign"].push(e.target.value);
      } else {
        let newArray = newMatchTypesSelected["autoCampaign"].filter(
          (item) => item !== e.target.value
        );
        newMatchTypesSelected["autoCampaign"] = newArray;
      }
    }
    if (matchType === "keywordMatch") {
      if (e.target.checked) {
        newMatchTypesSelected["manualCampaign"]["keywordMatch"].push(
          e.target.value
        );
      } else {
        let newArray = newMatchTypesSelected["manualCampaign"][
          "keywordMatch"
        ].filter((item) => item !== e.target.value);
        newMatchTypesSelected["manualCampaign"]["keywordMatch"] = newArray;
      }
    }
    if (matchType === "productMatch") {
      if (e.target.checked) {
        newMatchTypesSelected["manualCampaign"]["productMatch"].push(
          e.target.value
        );
      } else {
        let newArray = newMatchTypesSelected["manualCampaign"][
          "productMatch"
        ].filter((item) => item !== e.target.value);
        newMatchTypesSelected["manualCampaign"]["productMatch"] = newArray;
      }
    }
    setMatchTypesSelected(newMatchTypesSelected);
    wizardDataUpdate();
  };
  useEffect(() => {    
    if(selectedCampaigns.length > 0){
      wizardDataUpdate();
    }
 
  }, [selectedCampaigns]);


  function wizardDataUpdate() {
    console.log(selectedCampaigns);
    const campaigns = selectedCampaigns.map((campaign) => ({
      campaignId: String(campaign.campaignId),
      campaignName: campaign.campaignName,
    }));
    console.log("sdfsd", campaigns);

    let applyTo = {};
    applyTo = {
      file: null,
      campaigns: campaigns,
    };
    let formData = {
      type: "applyTo",
      applyTo: applyTo,
    };

    props.parentCallback(formData);
    props.wizardDataCallback(formData);
  }
  // function wizardDataUpdate(type, data) {
  //   let applyTo = [];
  //   for (let i = 0; i < formValues.length; i++) {
  //     let obj = {
  //       logicalAND: true,
  //       expressions: {
  //         operand: formValues[i].conditionFilter,
  //         operator: formValues[i].conditionName,
  //         value: [
  //           {
  //             inputType: "List",
  //             inputExpression: {
  //               operand: null,
  //               input: formValues[i].conditionValue,
  //             },
  //           },
  //         ],
  //         unit: null,
  //       },
  //     };
  //     applyTo.push(obj);
  //   }

  //   let marketPlace = newSelectedOptions;
  //   if (type === "marketplace") {
  //     for (let j = 0; j < data.length; j++) {
  //       if (marketPlace.indexOf(data[j].value) === -1) {
  //         marketPlace.push(data[j].value);
  //       }
  //     }
  //     setNewSelectedOptions(marketPlace);
  //   }
  //   let matchType = matchTypesSelected;
  //   let formData = {
  //     type: "applyto",
  //     marketPlace: marketPlace,
  //     applyTo: applyTo,
  //     matchType: matchType,
  //   };
  //   props.parentCallback(formData);
  //   props.wizardDataCallback(formData);
  // }

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
    wizardDataUpdate();
  };

  let addFormFields = () => {
    setFormValues([
      ...formValues,
      { conditionFilter: "", conditionName: "", conditionValue: "" },
    ]);
  };
  let duplicateFormFields = (i) => {
    let newFormValues = [...formValues];
    let duplicateRow = {
      conditionFilter: newFormValues[i].conditionFilter,
      conditionName: newFormValues[i].conditionName,
      conditionValue: newFormValues[i].conditionValue,
    };
    newFormValues.push(duplicateRow);
    setFormValues(newFormValues);
    wizardDataUpdate();
  };
  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
    wizardDataUpdate();
  };

  let handleSubmit = (event) => {};

  function buildApplyToDropdownData(listData, metaDataList) {
    let result = metaDataList;
    if (listData && result) {
      for (let i = 0; i < listData.length; i++) {
        listData[i]["id"] = i + 1;
        listData[i]["selected"] = false;
        let slectedConditionType = listData[i]["filterConditionType"];
        let conditionTypeList = result[slectedConditionType];
        listData[i]["conditionTypes"] = conditionTypeList;
      }
      setApplyTo(listData);
    }
  }
  const SubSelectOptions = (props) => {
    let { index, eleData } = props;
    if (eleData.conditionFilter) {
      let findIndex = applyTo.findIndex(
        (obj) => obj.name == eleData.conditionFilter
      );
      let conditionData = applyTo[findIndex];
      let optionData = conditionData.conditionTypes;
      if (optionData.length > 0) {
        return optionData.map((options, i) => (
          <option key={i} value={options.value}>
            {options.name}
          </option>
        ));
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const add = () => {
    setSelectedCampaigns2(selectedCampaigns)
    setSelectedCampaignShow(true);
  };

    const sync = async () => {
      setLoading(true)
      let userToken = localStorage.getItem("userToken");
      let AuthToken = "Bearer " + userToken;
      let url =
        "https://adsautomation.agilensmartservices.com/Rule/SyncCampigns";
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: AuthToken,
        },
        body: JSON.stringify({}),
       
      };

      try {
        const response = await fetch(url, requestOptions);
        const responceData = await response.json();
        if (responceData.success) {
          let result = responceData.result;
           console.log(result);
        } else {
          setLoading(false)
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFileChange = (event) => {
      setImportFile(event.target.files[0]);
     
    };

    const handleImport = () => {
      if (importFile) {
        setSelectedFile( importFile); 
      }
      handleClose(); 
    };
  
 

  return (
    <>
      <div className="wizard-step">
        {!metaDataLoading && !ruleDataLoading ? (
          <>
            {/* <div className="card">
              <Row>
                <Col md={12}>
                  <h4 className="title">Marketplace</h4>
                </Col>
                <Col md={4}>
                  <form>
                    <div>
                      <Select
                        key={selectedOptions.length} // Change key when the selected options change
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        onChange={handleSelect}
                        options={marketPlacesDropdown}
                        components={{
                          Option: InputOption,
                        }}
                        value={selectedOptions}
                      />
                    </div>
                  </form>
                </Col>
              </Row>
            </div>
            <div className="card">
              <Row>
                <Col md={12}>
                  <h4 className="title">Apply to</h4>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <form>
                    {formValues.map((element, index) => (
                      <div className="form-row mb-3" key={index}>
                        <div className="form-field-group">
                          <div className="form-field">
                            <select
                              className="form-select"
                              name="conditionFilter"
                              value={element.conditionFilter || ""}
                              onChange={(e) => {
                                handleChange(index, e);
                              }}
                            >
                              <option value="">--Select--</option>
                              {applyTo.map((list, i) => (
                                <option value={list.name}>
                                  {list.displayName}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="form-field">
                            <select
                              className="form-select"
                              name="conditionName"
                              value={element.conditionName || ""}
                              onChange={(e) => handleChange(index, e)}
                            >
                              <option value="">--Select--</option>
                              <SubSelectOptions
                                index={index}
                                eleData={element}
                              />
                            </select>
                          </div>
                          <div className="form-field">
                            <input
                              type="text"
                              placeholder="Enter Keyword"
                              name="conditionValue"
                              className="form-control"
                              value={element.conditionValue || ""}
                              onChange={(e) => handleChange(index, e)}
                            />
                          </div>
                        </div>
                        <div className="form-action a-2">
                          <div
                            className="icon-box-white cursor"
                            onClick={() => duplicateFormFields(index)}
                          >
                            <img src={DuplicateIcon} alt="" />
                          </div>
                          {index !== 0 && (
                            <div
                              className="icon-box-gray cursor"
                              onClick={() => removeFormFields(index)}
                            >
                              <img src={RemoveIcon} alt="" />
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </form>
                  <div>
                    <button
                      type="button"
                      className="btn btn-link add-more-btn"
                      onClick={() => addFormFields()}
                    >
                      <i className="circle-add-icon"></i> Add More
                    </button>
                  </div>
                </Col>
              </Row>
            </div> */}
            <Row>
              <Col md={5}>
                <div className="header-search d-flex">
                  <button type="form-control">
                    <i className="fa fa-search"></i>
                  </button>
                  <input type="text" placeholder="" className="form-control" id="applay-search"  style={{color:"black!important " ,border:"none"}}
                      onKeyDown={handleChangeInput}  />
                </div>
              </Col>
              <Col className="text-end">
              <button className="add me-2" onClick={sync}>Sync</button>
                <button className="add me-2" onClick={handleShow}>Import</button>
                <button className="add" onClick={add}>
                  Add
                </button>
              </Col>
              <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Import File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
      
       
        <Form.Control type="file" className="file"  onChange={handleFileChange}/>
   
        </Modal.Body>
        <Modal.Footer>
       
          <Button className="btn btn-primary add-rules-btn pt-2" style={{ height: "43px !important" }} onClick={handleImport}>
            Import
          </Button>
        </Modal.Footer>
      </Modal>
            </Row>
            {!ruleDataLoading && (
              <div className="card mt-2 ">
                {/* <form>
                  <Row>
                    <Col md={12}>
                      <h4 className="title">Match Types</h4>
                    </Col>

                    <Col md={6}>
                      {matchTypes.autoCampaign.length > 0 && (
                        <div className="gray-box">
                          <div className="gray-box-header">
                            <div>
                              <h4 className="title">Auto Campaign:</h4>
                            </div>
                            <div className="text-end">
                              <button type="button" className="btn btn-link">
                                Select All
                              </button>
                            </div>
                          </div>
                          <div className="option-list-container mt-2">
                            {matchTypes.autoCampaign.map((list, i) => {
                              let findIndex = -1;
                              if (pageData.matchType.autoCampaign) {
                                findIndex =
                                  pageData.matchType.autoCampaign.findIndex(
                                    (ele) => ele == list
                                  );
                              }
                              if (findIndex > -1) {
                                return (
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value={list}
                                      id={"autoCampain" + i}
                                      onChange={(e) =>
                                        matchTypesSelect("autoCampaign", e)
                                      }
                                      defaultChecked={true}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={"autoCampain" + i}
                                    >
                                      {list}
                                    </label>
                                  </div>
                                );
                              } else {
                                return (
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value={list}
                                      id={"autoCampain" + i}
                                      onChange={(e) =>
                                        matchTypesSelect("autoCampaign", e)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={"autoCampain" + i}
                                    >
                                      {list}
                                    </label>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>
                      )}
                    </Col>
                    <Col md={6}>
                      <div className="gray-box">
                        <div className="gray-box-header">
                          <div>
                            <h4 className="title">Manual Campaign:</h4>
                          </div>
                          <div className="text-end">
                            <button type="button" className="btn btn-link">
                              Select All
                            </button>
                          </div>
                        </div>

                        <Row>
                          {matchTypes.manualCampaign.keywordMatch.length >
                            0 && (
                            <Col>
                              <div className="option-list-container mt-2">
                                <h6>Keyword Targeting</h6>
                                {matchTypes.manualCampaign.keywordMatch.map(
                                  (list, i) => {
                                    let findIndex = -1;
                                    if (
                                      pageData.matchType.manualCampaign &&
                                      pageData.matchType.manualCampaign
                                        .keywordMatch
                                    ) {
                                      findIndex =
                                        pageData.matchType.manualCampaign.keywordMatch.findIndex(
                                          (ele) => ele == list
                                        );
                                    }
                                    if (findIndex > -1) {
                                      return (
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value={list}
                                            id={"keywordMath" + i}
                                            onChange={(e) =>
                                              matchTypesSelect(
                                                "keywordMatch",
                                                e
                                              )
                                            }
                                            defaultChecked={true}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={"keywordMath" + i}
                                          >
                                            {list}
                                          </label>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value={list}
                                            id={"keywordMath" + i}
                                            onChange={(e) =>
                                              matchTypesSelect(
                                                "keywordMatch",
                                                e
                                              )
                                            }
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={"keywordMath" + i}
                                          >
                                            {list}
                                          </label>
                                        </div>
                                      );
                                    }
                                  }
                                )}
                              </div>
                            </Col>
                          )}
                          {matchTypes.manualCampaign.productMatch.length >
                            0 && (
                            <Col>
                              <div className="option-list-container mt-2">
                                <h6>Product Targeting</h6>
                                {matchTypes.manualCampaign.productMatch.map(
                                  (list, i) => {
                                    let findIndex = -1;
                                    if (
                                      pageData.matchType.manualCampaign &&
                                      pageData.matchType.manualCampaign
                                        .productMatch
                                    ) {
                                      findIndex =
                                        pageData.matchType.manualCampaign.productMatch.findIndex(
                                          (ele) => ele == list
                                        );
                                    }
                                    if (findIndex > -1) {
                                      return (
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value={list}
                                            id={"productMath" + i}
                                            onChange={(e) =>
                                              matchTypesSelect(
                                                "productMatch",
                                                e
                                              )
                                            }
                                            defaultChecked={true}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={"productMath" + i}
                                          >
                                            {list}
                                          </label>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value={list}
                                            id={"productMath" + i}
                                            onChange={(e) =>
                                              matchTypesSelect(
                                                "productMatch",
                                                e
                                              )
                                            }
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={"productMath" + i}
                                          >
                                            {list}
                                          </label>
                                        </div>
                                      );
                                    }
                                  }
                                )}
                              </div>
                            </Col>
                          )}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </form> */}

                <Row className="Campaign-list mb-2" style={{ height: "400px" }}>
                  <div className="d-flex mb-2">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      checked={selectAllChecked}
                      onChange={() => handleCheckboxChange("selectAll")}
                    />
                    <h5>Select All</h5>
                  </div>
                  {!Loading ? (
                  <div
                    className=" mb-3"
                    style={{ height: "350px", overflowY: "auto" }}
                  >
                    {campaignData !== null ? (
                        <Row className="mb-3">
                        <Col>
                          {campaignData
                            .filter((_, index) => index % 2 === 0)
                            .map((campaign, index) => (
                              <div key={index} className="d-flex mb-2">
                                <input
                                  className="form-check-input me-2"
                                  type="checkbox"
                                  checked={selectedCampaigns.some(
                                    (selectedCampaign) =>
                                      selectedCampaign.campaignName ===
                                      campaign.campaignName
                                  )}
                                  onChange={() => handleCheckboxChange(campaign)}
                                />
                                <div>{campaign.campaignName}</div>
                              </div>
                            ))}
                        </Col>
                        <Col>
                          {campaignData 
                            .filter((_, index) => index % 2 !== 0)
                            .map((campaign, index) => (
                              <div key={index} className="d-flex mb-2">
                                <input
                                  className="form-check-input me-2"
                                  type="checkbox"
                                  checked={selectedCampaigns.some(
                                    (selectedCampaign) =>
                                      selectedCampaign.campaignName === campaign.campaignName
                                  )}
                                  onChange={() => handleCheckboxChange(campaign)}
                                />
                                <div>{campaign.campaignName}</div>
                              </div>
                            ))}
                        </Col>
                      </Row>
                    ):(
                      <Row className="text-center mt-5">
                       <div className="mt-5 pt-5">No campaigns available.</div>
                      </Row>
                    )}
                  
                  </div>
                  ):(<div className="loading-container">
                  <div
                    style={{
                      position: "absolute",
                      top: "30%",
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <CircularProgress
                      className="loading"
                      style={{ margin: "auto" }}
                    />
                  </div>
                </div>)}
                </Row>
                <Row className="Campaign-list">
                  <div
                    className="gray-box"
                    style={{ height: "400px", overflowY: "auto" }}
                  >
                    <div className="d-flex justify-content-between mb-2">
                      <h5>Selected Campaigns</h5>
                    </div>
                    {selectedCampignShow &&
                      selectedCampaigns2.map((selectedCampaign, index) => (
                        <div
                          key={index}
                          className="d-flex justify-content-between mb-2"
                        >
                          <div>{selectedCampaign.campaignName}</div>
                          <div>
                            <a
                              style={{ textDecoration: "underline" }}
                              onClick={() => handleDelete(selectedCampaign)}
                            >
                              Delete
                            </a>
                          </div>
                        </div>
                      ))}
                  </div>
                </Row>
              </div>
            )}
          </>
        ) : (
          <div className="loading-container">
            <div
              style={{
                position: "absolute",
                top: "30%",
                left: 0,
                width: "100%",
                height: "100%",
              }}
            >
              <CircularProgress
                className="loading"
                style={{ margin: "auto" }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AddRulesApplyto;
