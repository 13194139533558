/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import "../index.css";
import CreateRuleWizard from "../../../../components/Wizard/createRuleWizard";
import { Navigate, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { event } from "jquery";



interface Template {
  id: string;
  templateName: string;
}
function AddRules(props) {
  const [templates, setTemplates] = useState<Template[]>([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>("");
  const [metaData, setMetaData] = useState<any>([]);
  const [rulesMetaData, setRulesMetaData] = useState<any>({});
  const [name, setName] = useState<any>("");
  const [wizardData, setWizardData] = useState<any>({
    name: "",
    applyToWizard: [],
    ifWizard: [],
    forWizard: {},
    thenWizard: [],
    untilWizard: {},
  });
  const [applyTo, setApplyTo] = useState<any>([]);
  const navigate = useNavigate();
 
  const [targetCriteriaDetails, setTargetCriteriaDetails] = useState<any>([]);
  const [reportDuration, setReportDuration] = useState<any>("");
  const [actionCriteria, setActionCriteria] = useState<any>([]);
  const [actionUpToCriteria, setActionUpToCriteria] = useState<any>([]);
  

  const handleTemplateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = e.target.value;
    setSelectedTemplateId(selectedId);
    console.log("Selected Template ID:", selectedTemplateId);
    if(selectedTemplateId !== ""){
    
       
    
    }
  
  };

  useEffect(() => {
    if(selectedTemplateId !== ""){
      localStorage.setItem("templateId",selectedTemplateId )
      props.parentCallback("AddNewRules");
      navigate("/ads/create-automation-rules/" +selectedTemplateId);
      // templateCall()  
     
      // event.preventDefault();
    }
  }, [selectedTemplateId])

  let loadPanel = (panelName, event) => {
    console.log(panelName);
    
    props.parentCallback(panelName);
    event.preventDefault();
  };
  useEffect(() => {
    template();
    localStorage.removeItem("templateId")
    
  }, []);
 

  const template = async () => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    let url = "https://adsautomation.agilensmartservices.com/Rule/Templates";
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
    };

    try {
      const response = await fetch(url, requestOptions);
      const responceData = await response.json();
      if (responceData.success) {
        let result = responceData.result;
        setTemplates(result);
      } else {
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const templateCall = async () => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    let url = "https://adsautomation.agilensmartservices.com/Rule/Template/"+selectedTemplateId;
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: AuthToken,
      },
    };

    try {
      const response = await fetch(url, requestOptions);
      const responceData = await response.json();
      if (responceData.success) {
        let result = responceData.result;
        console.error("Get Rules: ", result);
        let objWizard = {
          id: result.id,
          template:result.template,
          name: result.ruleName,
          applyToWizard: result.applyTo,
          ifWizard: result.targetCriteria,
          forWizard: result.frequency,
          thenWizard: result.actionCriteria.data,
          untilWizard: result.automationCriteria,
        };
        console.log(objWizard);
        
        setName(result.ruleName);
     
        setApplyTo(result.applyTo);
   
        setTargetCriteriaDetails(result.targetCriteria);
        setReportDuration(result.frequency);
        setActionCriteria(result.actionCriteria.data);
        setActionUpToCriteria(result.automationCriteria);
        // setChecked(result.template)
        setWizardData(objWizard);
    
        console.log(wizardData);
        props.parentCallback("AddNewRules");
        // const newUrl = `/ads/create-automation-rules/${selectedTemplateId}`;
        // window.history.replaceState({}, "", newUrl);
    navigate("/ads/create-automation-rules/" +selectedTemplateId);
       
      } else {
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };



  return (
    <>
      <div className="add-rule-container">
        <Row>
          <Col>
            <h4>Add Rule</h4>
            <p>
              You can choose rule from our template below or you can also create
              your custom rule.
            </p>
          </Col>
        </Row>
        <Row className="">
          {/* <h5>Our template</h5> */}
          <Col md={4}>
            <div
              className="rule-box mt-2"
              onClick={(eve) => loadPanel("AddNewRules", eve)}
            >
              <i className="radio-icon"></i>
              <div className="box-header ">
                {" "}
                <i className="fa-solid fa-ellipsis-vertical"></i>Custom Rules
              </div>
              <div className="box-body p-2">
                <p>You can create your own rules according to what you want.</p>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="rule-box mt-2" >
              <i className="radio-icon"></i>
              <div className="box-header">
                <i className="fa-solid fa-ellipsis-vertical"></i>Template
              </div>
              <div className="box-body">
                <form>
                  <select
                    className="form-select"
                    value={selectedTemplateId}
                    aria-label="Default select example"
                    
                    onChange={(e) => handleTemplateChange(e)}
                  >
                    <option value="">--select--</option>
                    {templates.map((template) => (
                      <option key={template.id} value={template.id} onClick={(eve) => loadPanel("AddNewRules", eve)} >
                        {template.templateName}
                      </option>
                    ))}
                  </select>
                </form>
              </div>
            </div>
          </Col>
          {/* <Col md={4}>
            <div className="rule-box">
              <i className="radio-icon"></i>
              <div className="box-header"><i className="fa-solid fa-ellipsis-vertical"></i> Increase ROI</div>
              <div className="box-body">
                <form>
                <select className="form-select" defaultValue={""} aria-label="Default select example">
                  <option value="">--select--</option>
                  <option value="Gentle approach">Gentle approach</option>
                </select>
                </form>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="rule-box">
              <i className="radio-icon"></i>
              <div className="box-header"><i className="fa-solid fa-ellipsis-vertical"></i> Negative Targets</div>
              <div className="box-body">
                <form>
                <select className="form-select" defaultValue={""} aria-label="Default select example">
                  <option value="">--select--</option>
                  <option value="Gentle approach">Gentle approach</option>
                </select>
                </form>
              </div>
            </div>
          </Col> */}
          {/* <h5 className="mt-4"></h5> */}
        </Row>

      </div>
    </>
  );
}

export default AddRules;
