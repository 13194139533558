import React, { Component } from "react";
import "./index.css";
import OnOffIcon from "../../assets/images/icons/on-off-icon.svg";
import BellIcon from "../../assets/images/icons/bell-icon.svg";
import GroupMemberIcon from "../../assets/images/icons/group-member-icon.svg";
import ProfileIcon from "../../assets/images/icons/profile-icon.svg";
import ActiveIcon from "../../assets/images/icons/acive-green-icon.svg";
import ProfileDropIcon from "../../assets/images/icons/profile-down-arrow.svg";
import upgradetoproImage from "../../assets/images/icons/upgradetopro.jpg";
import addaccount from "../../assets/images/icons/addaccount.png";
import logouticon from "../../assets/images/icons/logout.png";
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";

interface ProfileOption {
  value: string; // Adjust the type according to your data
  label: string;
}
interface profileId {
  onSearch: (selectedAccount: string) => void;
}
interface HeaderProps extends profileId {}

class Header extends Component<HeaderProps> {
  state = {
    userNames: localStorage.getItem("userName"),
    userImage: localStorage.getItem("userimg"),
    profileOptions: [] as ProfileOption[],
    selectedAccount: "",
  };

  constructor(props: profileId) {
    super(props);
    this.handleAccountChange = this.handleAccountChange.bind(this);
  }

  handleAccountChange(event) {
    const selectedValue = event.target.value;
    this.setState({
      selectedAccount: selectedValue,
    });
    // localStorage.setItem("account", selectedValue);
    this.props.onSearch(selectedValue);
    console.log("Selected Account:", selectedValue);
  }

  componentDidMount() {
    this.getProfile();
  }

  getProfile = async () => {
    let userToken = localStorage.getItem("userToken");
    let AuthToken = "Bearer " + userToken;
    try {
      const response = await fetch(
        "https://adsautomation.agilensmartservices.com/Users/Profile",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: AuthToken,
          },
        }
      );

      const responseData = await response.json();
      const profiles = responseData.result.awsProfilesPreferences;
      const profileOptionss = profiles
        .filter((profile) => profile.isActive === true)
        .map((profile) => ({
          value: profile.profileId,
          label: profile.profileName,
        }));
      console.log(profileOptionss);

      localStorage.removeItem("account");
      this.props.onSearch(profileOptionss[0].value);
      this.setState(
        {
          profileOptions: profileOptionss,
          selectedAccount: profileOptionss[0].value,
        },
        () => {}
      );
      this.setState({
        userName: responseData.result.name,
        userImage: responseData.result.image,
      });
      localStorage.setItem("userimg", responseData.result.image);
    } catch (error) {
      console.error("Error fetching profile:", error);
    }
  };

  logoutHandler = () => {
    localStorage.removeItem("userimg");
  };

  render() {
    const { userImage } = this.state;

    return (
      <Navbar expand="lg" className="bg-body-tertiary page-top-bar">
        <Container fluid>
         
          <div className="account ms-2">
            <select
              className="form-select"
              name="TypeSelect"
              id="TypeSelect"
              value={this.state.selectedAccount}
              onChange={this.handleAccountChange}
            >
              {this.state.profileOptions.map((profile) => (
                <option key={profile.value} value={profile.value}>
                  {profile.label}
                </option>
              ))}
            </select>
          </div>
          {/* <Navbar.Toggle aria-controls="navbarScroll" /> */}
        
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            ></Nav>

            <Nav className="profile-view justify-content-end">
          
              <div className="vr"></div>
              <Nav.Link href="#" className="bell ms-2 me-2 mt-1">
                <img src={BellIcon} alt="" />
              </Nav.Link>
              <div className="vr"></div>
              <NavDropdown
                className="ms-4"
                title={
                  <div>
                    <div className="profile-icon">
                      <div className="profile-img">
                        <div className="profile-icon d-flex align-items-center">
                          <img src={ProfileIcon} alt="img" />
                          <span
                            className="ms-2"
                            style={{
                              color: "#6b7280",
                              textDecoration: "none !important",
                            }}
                          >
                            {this.state.userNames}
                          </span>

                          <span>
                            <img src={ProfileDropIcon} alt="drop icon" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                id="navbarScrollingDropdown"
              >
                <NavDropdown.Item>
                  <div
                    className="myProfileLogo"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      padding: "8px",
                    }}
                  >
                    <i className="profile-logo" />
                    My Profile
                  </div>
                </NavDropdown.Item>
                {/* <NavDropdown.Divider style={{ borderTop: "none" }} /> */}
                <NavDropdown.Item
                  href="/sign-in"
                  className=""
                  onClick={this.logoutHandler}
                >
                  <div
                    className="myProfileLogo"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                      padding: "8px",
                    }}
                  >
                    <img
                      src={logouticon}
                      style={{ width: "20px", height: "20", marginLeft: "1px" }}
                      alt="logout"
                    />
                    Logout
                  </div>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          
        </Container>
      </Navbar>
    );
  }
}

export default Header;
